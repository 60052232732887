import "./index.scss";

const CourseList = ({ studentInfo }: any) => {
  return (
    <>
      <div className="course-container">
        <p className="courses-p">
          COURSES REGISTERED FOR {studentInfo?.semesterInNumber}. (TOTAL
          REGISTERED COURSES = {studentInfo?.courseCodes?.length})
        </p>

        <div className="courses">
          <div className="course">
            <p className="p-s">
              <b>S/No</b>
            </p>
            <p className="p-c">
              <b>Course code</b>
            </p>
          </div>
          <div className="course">
            <p className="p-s">
              <b>S/No</b>
            </p>
            <p className="p-c">
              <b>Course code</b>
            </p>
          </div>
          <div className="course">
            <p className="p-s">
              <b>S/No</b>
            </p>
            <p className="p-c">
              <b>Course code</b>
            </p>
          </div>
          {studentInfo?.courseCodes?.map((course: any, index: number) => (
            <div className="course" key={index}>
              <p className="p-s">{index + 1}</p>
              <p className="p-c">{course?.courseCode}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CourseList;
