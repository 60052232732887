
import {appConstantsUGradAdmisssions} from "../../../action-constants/admissions/undergraduate"
// import {ReducerParams} from "../../../types"



const initialState =  {};


export const uGradGetSessionReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradAdmisssions.CHECK_CURRENT_SESSION_PENDING:
            return {
                request_status: appConstantsUGradAdmisssions.CHECK_CURRENT_SESSION_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradAdmisssions.CHECK_CURRENT_SESSION_SUCCESS:
            return {
                request_status: appConstantsUGradAdmisssions.CHECK_CURRENT_SESSION_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradAdmisssions.CHECK_CURRENT_SESSION_FAILURE:
            return {
                request_status: appConstantsUGradAdmisssions.CHECK_CURRENT_SESSION_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradAdmisssions.CHECK_CURRENT_SESSION_RESET:
            return {
                request_status: appConstantsUGradAdmisssions.CHECK_CURRENT_SESSION_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const uGradCheckAdmissionStatusReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_PENDING:
            return {
                request_status: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_SUCCESS:
            return {
                request_status: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_FAILURE:
            return {
                request_status: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_RESET:
            return {
                request_status: appConstantsUGradAdmisssions.CHECK_ADMISSION_PROCESS_STATUS_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const uGradPrintMyAcceptanceInvoiceReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_PENDING:
            return {
                request_status: appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_SUCCESS:
            return {
                request_status: appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_FAILURE:
            return {
                request_status: appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_RESET:
            return {
                request_status: appConstantsUGradAdmisssions.GET_ACCEPTANCE_INVOICE_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const uGradPrintMySchoolFeeInvoiceReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_PENDING:
            return {
                request_status: appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_SUCCESS:
            return {
                request_status: appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_FAILURE:
            return {
                request_status: appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_RESET:
            return {
                request_status: appConstantsUGradAdmisssions.GET_SCHOOL_FEE_INVOICE_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const uGradPrintMyAdmissionLetterReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_PENDING:
            return {
                request_status: appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_SUCCESS:
            return {
                request_status: appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_FAILURE:
            return {
                request_status: appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_RESET:
            return {
                request_status: appConstantsUGradAdmisssions.GET_ADMISSION_LETTER_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

