import DoneTick from "../../../../../assets/done-tick.png"
import OpenTick from "../../../../../assets/open-tick.png"

export const StepGuide = ({ step, isToPrint }: any) => {
    return (
        <div className={`steps_guide ${isToPrint?"to-print":""}`}>
            <div className="each_step">
                <div className={step >= 1 ? "step_tick done" : "step_tick"}>
                    <img src={step >= 1 ? DoneTick : OpenTick} alt="" />
                </div>
                <div className="step_txt">Step One</div>
            </div>
            <div className={step >= 1 ? "step_progress done" : "step_progress"}></div>
            <div className="each_step">
                <div className={step >= 2 ? "step_tick done" : "step_tick"}>
                    <img src={step >= 2 ? DoneTick : OpenTick} alt="" />
                </div>
                <div className="step_txt">Step two</div>
            </div>
            <div className={step >= 2 ? "step_progress done" : "step_progress"}></div>
            <div className="each_step">
                <div className={step >= 3 ? "step_tick done" : "step_tick"}>
                    <img src={step >= 3 ? DoneTick : OpenTick} alt="" />
                </div>
                <div className="step_txt">Step three</div>
            </div>
            <div className={step >= 3 ? "step_progress done" : "step_progress"}></div>
            <div className="each_step">
                <div className={step >= 4 ? "step_tick done" : "step_tick"}>
                    <img src={step >= 4 ? DoneTick : OpenTick} alt="" />
                </div>
                <div className="step_txt">Final Step</div>
            </div>
        </div>
    )
}