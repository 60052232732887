import React, { useEffect, useState } from "react";
import PageWrapper from "../PageWrapper";
import Tabs from "../Tabs";

const PaymentInvoice = ({ pageProps }: any) => {
  const [student, setStudent] = useState<any>({});
  useEffect(() => {
    if (pageProps?.UGradProfileRequest?.request_data?.response) {
      setStudent(pageProps?.UGradProfileRequest?.request_data?.response);
    }
  }, [pageProps]);
  return (
    <PageWrapper student={student}>
      <Tabs studentInfo={student} />
    </PageWrapper>
  );
};

export default PaymentInvoice;
