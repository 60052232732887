import "./index.scss";

const ExamRules = () => {
  return (
    <div className="exam-rules-wrapper">
      <div className="exam-rules">
        <h2>EXAMINATION RULES </h2>
        <p>
          1.&nbsp;Students shall be at Examination venue at least 30 minutes
          before the advertised commencement time of examination.
        </p>
        <p>
          2.&nbsp;A student shall bring examination card and I.D card to be
          displayed in a prominent position on the desk in the examination
          venue.
        </p>
        <p>
          3.&nbsp;No book, printed paper, programmed calculator, GSM(handset)
          shall be taken into any examination venue by any student.
        </p>
        <p>
          4.&nbsp; A student must not, during examination, directly or
          indirectly give any form of assistance to any student or permit any
          other student to copy from, or use his/her papers during examination.
          Similarly, a student must not directly or indirectly accept any form
          of assistance from other students.
        </p>
        <p>
          5.&nbsp; If a student needs anything, the invigilator should be called
          upon by the student by raising up his/her hand.
        </p>
        <p>
          6.&nbsp;A student may only be permitted by an invigilator to leave the
          examination room during the course of an examination provided he/she:
          <div className="sub-rule">
            <p>a.&nbsp;has stayed up to 1 hour in the examination hall.</p>
            <p>b.&nbsp;has handed over his/her script to the invigilator.</p>
          </div>
        </p>
        <p>
          7.&nbsp;A student who leaves the examination room shall not be
          readmitted unless throughout the period of his/her absence he/she has
          been continually under the supervision of an invigilator or Assistant
          invigilator.
        </p>
        <p>
          8.&nbsp;A student who arrives 15 minutes late after the start of an
          examination paper may be allowed to enter into the examination venue
          but shall not be giving extra time to take the examination. However, a
          student who arrives 30 minutes late after the start of any examination
          paper may not be allowed to enter the examination venue to take the
          examination.
        </p>
      </div>
    </div>
  );
};

export default ExamRules;
