import React from 'react'
import { UndergraduatePortal } from '../../../../shared/templates/portal/undergraduate'
import PaymentHistory from './PaymentHistory'
import { connect } from "react-redux";

const PaymentHistoryWrap = (pageProps: any) => {
  return (
    <UndergraduatePortal
    title="ALL PAYMENTS RECEIPT HISTORY"
    childComponent={ <PaymentHistory pageProps={pageProps}/>}
  />
  )
}


const mapStateToProps = (state: any) => ({
  
  uGradLoginRequest: state.allOnboardingReducers.uGradLogin,
});

export default connect(mapStateToProps)(PaymentHistoryWrap);

