import { useState } from "react";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import "./index.scss";
import BlueEye from "../../../assets/Blue-Eye.png";
import { useSelector } from "react-redux";
import { formatDate } from "./Tabs";
const ClearanceRequest = ({ setShow, setDocs, setDocumentType }: any) => {
  const { clearanceRequests } = useSelector(
    (state: any) => state.eDocumentation
  );
  return (
    <div className="clearance-table-container">
      <Table bordered hover className="clearance-request-table">
        <thead>
          <tr>
            <th>DOCUMENT</th>
            <th>REVIEWER</th>
            <th>SESSION</th>
            <th>DATE</th>
            <th>DOCUMENT DISPLAY</th>
            <th>VIEW</th>
            <th>STATUS</th>
            <th>COMMENT</th>
          </tr>
        </thead>
        <tbody>
          {clearanceRequests?.map((request: any, index: any) => {
            return (
              <tr key={index}>
                <td>{request?.documentType}</td>
                <td>ADMIN</td>
                <td>{request?.session}</td>
                <td>{formatDate(request?.dateUploaded)}</td>
                <td className="document-display">
                  {request?.downloadUrlFront ? (
                    <img
                      src={request?.downloadUrlFront}
                      alt="Front page"
                      className="table-image"
                    />
                  ) : null}{" "}
                  {request?.downloadUrlBack ? (
                    <img
                      src={request?.downloadUrlBack}
                      alt="Back page"
                      className="table-image"
                    />
                  ) : null}
                </td>
                <td>
                  <img
                    src={BlueEye}
                    alt="Eye icon"
                    className="eye-icon"
                    onClick={() => {
                      setDocs([
                        request?.downloadUrlFront,
                        request?.downloadUrlBack,
                      ]);
                      setDocumentType(request?.documentType);
                      setShow(true);
                    }}
                  />
                </td>
                <td>{request?.status}</td>
                <td>
                  {request?.status === "REJECTED" && request?.comment
                    ? request?.comment
                    : "-"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ClearanceRequest;
