import React, { useState } from "react";
import Welcome from "./welcome";
import Instructions from "./instructions";
import Payments from "../../assets/payments.png";
import "./index.scss";
import QuickLinks from "./quickLinks";
import Header from "./header";
import { Link } from "react-router-dom";

const LandingPage = () => {
    const [activeTab, setActiveTab] = useState("welcome");
    let yearTxt = new Date().getFullYear();
    return (
        <div className="landing-page-container">
            <div className="landing-page">
                <Header
                    pageTitle="Undergraduate Student Online Registration"
                    showFirstSectionImage
                />
                <div className="second-section">
                    <div className={activeTab !== "links" ? "tabs" : "tabs with-line"}>
                        <button
                            onClick={() => setActiveTab("welcome")}
                            className={activeTab === "welcome" ? "active" : "tab-button"}
                        >
                            <span
                                className={
                                    activeTab === "welcome"
                                        ? "white-dot dot"
                                        : "black-dot dot"
                                }
                            ></span>{" "}
                            WELCOME
                        </button>
                        <button
                            onClick={() => setActiveTab("instructions")}
                            className={activeTab === "instructions" ? "active" : "tab-button"}
                        >
                            <div
                                className={
                                    activeTab === "instructions" ? "white-dot dot" : "black-dot dot"
                                }
                            ></div>
                            INSTRUCTIONS
                        </button>
                        <button
                            onClick={() => setActiveTab("links")}
                            className={activeTab === "links" ? "active" : "tab-button"}
                        >
                            <div
                                className={
                                    activeTab === "links" ? "white-dot dot" : "black-dot dot"
                                }
                            ></div>
                            QUICK LINKS
                        </button>
                    </div>
                    {activeTab === "instructions" && <Instructions />}
                    {activeTab === "links" && <QuickLinks />}
                    {activeTab === "welcome" && <Welcome />}
                </div>
                <div className="payment-img">
                    <img src={Payments} alt="Payments" />
                </div>

            </div>
            <div className="footer">
          <p> <span>&copy; {yearTxt}</span> IBB University. All rights reserved</p>
          <p>Developed by <Link to='https://gigbadi.com.ng/' target="_blank" className="footer-link">Gigbadi</Link></p>
        </div>
        </div>
    );
};

export default LandingPage;