import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AlertMsg } from "../../../../shared/components/alert-msg";
import Logo from "../../../../assets/logo2.png";
import PwSeen from "../../../../assets/pw-visible.png";
import PwHide from "../../../../assets/pw-hidden.png";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { uGradOnboarding } from "../../../../redux/action/onboarding/undergrad";
import { appConstantsUGradOnboarding } from "../../../../redux/action-constants/onboarding";

import "../index.scss";

const UnderGradForgotPwRequest = async ({ pageProps, payload }: any) => {
  await pageProps.UnderGradForgotPw(payload);
};

const UGForgotPassword = (pageProps: any) => {
  const [isPwShown, setShowPw] = useState<boolean>(false);
  const screenResolution: any = useWindowSize();
  const reloadVariable = localStorage.getItem("adRld");

  let yearTxt = new Date().getFullYear();

  useEffect(() => {
    if (!reloadVariable) {
      localStorage.setItem("adRld", JSON.stringify(true));
      window.location.reload();
    }
  }, [reloadVariable]);

  let requestInfo = pageProps?.uGradForgotPwRequest;

  useEffect(() => {
    UnderGradForgotPwRequest({
      pageProps,
      payload: "CLEAR",
    });
    // pageProps.logoutAction();
  }, []);
  let checkValidationSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Required"),
  });
  let initialValues: any = { matricNumber: "" };
  return (
    <>
      <div className="onboarding_wrap reset">
        <div className="login-form-wrapper">
          <div className="onboarding_content splitted">
            {screenResolution?.width >= 767 && (
              <div className="each_side summary ipad-view">
                <div>
                  <div className="website_link">
                    {" "}
                    <a href="https://ibbu.edu.ng/">Main Website</a>{" "}
                  </div>
                  <div className="logo">
                    <a href="https://ibbu.edu.ng/">
                      <img src={Logo} alt="" />
                    </a>
                  </div>
                  <div className="school_name">
                    IBRAHIM BADAMASI BABANGIDA UNIVERSITY
                  </div>
                  <div className="student_type_txt">
                    FULL-TIME STUDENT <br /> RESET PASSWORD
                  </div>
                </div>
              </div>
            )}
            {screenResolution?.width <= 766 && (
              <div className="mobile_heading">
                <div className="logo">
                  <a href="https://ibbu.edu.ng/">
                    <img src={Logo} alt="" />
                  </a>
                </div>
                <div className="school_name">
                  IBRAHIM BADAMASI BABANGIDA UNIVERSITY
                </div>
                {/* <div className="student_type_txt">FULL-TIME  STUDENT <br /> SIGN IN</div> */}
              </div>
            )}
            <div className="each_side form_info">
              <div className="form_heading">UNDERGRADUATE PORTAL</div>
              <div className="form_heading">FORGOT PASSWORD</div>
              {requestInfo.request_status !==
                appConstantsUGradOnboarding?.FORGOT_PASSWORD_SUCCESS &&
                <Formik
                  initialValues={initialValues}
                  validationSchema={checkValidationSchema}
                  onSubmit={async (values) => {
                    let { matricNumber } = values;
                    let payload: any = `?matricNumber=${matricNumber}`;
                    await UnderGradForgotPwRequest({ pageProps, payload });
                  }}
                >
                  {(props: FormikProps<any>) => {
                    const {
                      values,
                      touched,
                      errors,
                      // handleBlur,
                      handleChange,
                      // isSubmitting,
                    } = props;
                    return (
                      <Form className="form-wrapper">
                        <div className="form-group">
                          <label className="label">Matric Number </label>
                          <div
                            className={
                              errors.matricNumber && touched.matricNumber
                                ? "invalid-input-wrap"
                                : ""
                            }
                          >
                            <Field
                              value={values.matricNumber}
                              id="matricNumber"
                              name="matricNumber"
                              type="text"
                              onChange={handleChange("matricNumber")}
                              placeholder="Enter Matric Number"
                              className={`input-wrap ${errors.matricNumber && touched.matricNumber
                                ? "is-invalid-input "
                                : !errors.matricNumber &&
                                  values.matricNumber !== ""
                                  ? "valid-input"
                                  : ""
                                }`}
                            />
                          </div>
                          <ErrorMessage
                            name="matricNumber"
                            className="form-input-error"
                            component="div"
                          />
                        </div>

                        {requestInfo.request_status ===
                          appConstantsUGradOnboarding?.FORGOT_PASSWORD_FAILURE && (
                            <AlertMsg
                              type="error"
                              message={requestInfo?.request_data.error}
                            />
                          )}
                        <div className="submit_cta">
                          <button
                            disabled={requestInfo?.is_request_processing}
                            className="btn shaded"
                            type="submit"
                          >
                            {requestInfo?.is_request_processing
                              ? "Please wait..."
                              : "Reset Password"}
                          </button>
                        </div>
                        <div className="forgot_cta">
                          <Link to="/undergraduate/login">Sign In</Link>
                        </div>
                        {screenResolution?.width <= 766 && (
                          <div className="website_link">
                            {" "}
                            <a href="https://ibbu.edu.ng/">Main Website</a>{" "}
                          </div>
                        )}
                      </Form>
                    );
                  }}
                </Formik>
              }
              {requestInfo.request_status ==
                appConstantsUGradOnboarding?.FORGOT_PASSWORD_SUCCESS &&
                <>
                  <AlertMsg
                    type="success"
                    message={requestInfo?.request_data?.response}
                  />
                  <div className="forgot_cta">
                    <Link to="/undergraduate/login">Sign In</Link>
                  </div>
                </>
              }
            </div>
          </div>

        </div>

        {screenResolution?.width >= 767 && (
          <div className="login-footer">
            <p> <span>&copy; {yearTxt}</span> IBB University. All rights reserved</p>
            <p>Developed by <a href="https://gigbadi.com.ng/" target="_blank" className="footer-link">Gigbadi</a></p>
          </div>
        )}

        {screenResolution?.width < 767 && (
          <div className="mobile-footer">
            <p>
              {" "}
              <span>&copy; {yearTxt}</span> IBB University. All rights reserved
            </p>
            <p>
              Developed by{" "}
              <Link
                to="https://gigbadi.com.ng/"
                target="_blank"
                className="footer-link"
              >
                Gigbadi
              </Link>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  UnderGradForgotPw: uGradOnboarding.UnderGradForgotPw,

};
const mapStateToProps = (state: any) => ({
  uGradForgotPwRequest: state.allOnboardingReducers.uGradForgotPw,
});
export default connect(mapStateToProps, mapDispatchToProps)(UGForgotPassword);
