import React from 'react'
import PrintCourseReg from './PrintCourseReg'
import { connect } from "react-redux";
import { UndergraduatePortal } from '../../../../shared/templates/portal/undergraduate';
import { ToastContainer } from 'react-toastify';



const PrintCourseRegWrap = (pageProps:any) => {
  return (
    <>
       <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <UndergraduatePortal
        title="Course Registration History"
        childComponent={ <PrintCourseReg pageProps={pageProps}/>}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  
  uGradLoginRequest: state.allOnboardingReducers.uGradLogin,
});

export default connect(mapStateToProps)(PrintCourseRegWrap);
