import React from "react";
import { Link } from "react-router-dom";

const Welcome = () => {
  return (
    <div className="main-content">
      <p>
      Welcome to the <span>UNDERGRADUATE STUDENT ONLINE REGISTRATION</span>  portal of Ibrahim Badamasi Babangida University. 
      The IBBUL UNDERGRADUATE Student Online Information System allows UNDERGRADUATE students of the University
       to access Online portal /resources. These resources include online student personal data registration, 
       online course registration, checking of results, course and examination timetables among other services. 
      </p>
      <h4>
        <Link
         to="/undergraduate/login" target="_blank" rel="noopener noreferrer"
        >
          CLICK HERE TO LOGIN TO THE UNDERGRADUATE STUDENT PORTAL
        </Link>
      </h4>
      <p>
      To access the services, a student must activate an account on the system using instructed procedure and 
      register using your complete academic details. This activation is a one-time action per session that will 
      be used to setup the student's profile within the system. The student account also has the bonus facility 
      to enable the student view all his/her results for an academic session(when activated). 
      </p>
      
      <div>
      All enquiries, complains and suggestion should be directed to the Portal administrator or
       any staff of the ICT/Computer centre Or you can send a message using the contact form on the University's main website contact page.
      </div>
    </div>
  );
};

export default Welcome;