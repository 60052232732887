import { AppHeader } from "../../../components/admissions/header";
import { AppFooter } from "../../../components/admissions/footer";
import { ChildrenCompProps } from "../../../../types";
import "./index.scss";

export const UndergraduateAdmissionStatusChecker: React.FC<
  ChildrenCompProps
> = (props) => {
  const { childComponent } = props;

  return (
    <div className="admissions_status_checker_wrap">
      <div className="page_wrap">
        <AppHeader />
        <div className="status_checker_content">{childComponent}</div>
      </div>
      <AppFooter />
    </div>
  );
};
