import { Button } from "react-bootstrap";

const MobileTable = ({ paymentDetails }: any) => {
  return (
    <div className="past_data">
      {paymentDetails.length > 0 ? (
        <div className="table-container">
          <table className="course-table">
            <tbody>
              {paymentDetails.map((data: any, index: number) => {
                const encodedSession = encodeURIComponent(data.session);
                const encodedType = encodeURIComponent(data.type);
                return (
                  <tr key={index}>
                    <td data-label="SESSION">
                      <span>{data?.session}</span>
                    </td>
                    <td data-label="LEVEL">
                      <span>{data?.level}</span>
                    </td>
                    <td data-label="PAYMENT TYPE">
                      <span>{data?.paymentType}</span>
                    </td>
                    <td data-label="DOWNLOAD" className="button-span">
                      <span>
                        <Button
                          className="paid-btn"
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`https://ibbuportals.ibbu.edu.ng/Verify?code=${data.studentId}&session=${encodedSession}&type=${encodedType}`}
                        >
                          Download
                        </Button>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No receipt is currently available.</p>
      )}
    </div>
  );
};

export default MobileTable;
