import PageWrapper from "../../e-Documentation/PageWrapper";
import SiteList from "./SiteList";

const HostelsList = ({ student, setShowTermsModal }: any) => {
  return (
    <PageWrapper student={student}>
      <SiteList student={student} setShowTermsModal={setShowTermsModal} />
    </PageWrapper>
  );
};

export default HostelsList;
