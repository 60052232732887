import React from "react";
import { Link } from "react-router-dom";
const QuickLinks = () => {
  return (
    <div className="all_quick_links">
      <div className="each-item">
        <div className="item-heading">NEW STUDENTS</div>
        <div className="quick-links">
          <div className="must-read">
            New Students,  <Link
         to="/admissions/check-status" target="_blank" rel="noopener noreferrer"
        >CLICK HERE</Link>  to check your admission status & generate your acceptance fee payment slip,
            generate your admission notification/registration fee payment slip  &amp; also print your
            provisional admission letter.
          </div>
          <div className="item_head">The above link provide the following functionalities to New Student;</div>
          <ul className="main_items">
            <li>Checking of admission status.</li>
            <li> Generate your acceptance fee payment slip.</li>
            <li>Generate your admission notification/registration fee payment slip</li>
            <li>Print your provisional admission letter.</li>
          </ul>
          <div className="other_info">Direct Admission Letter Generation/Printing (Only for those that have completed the entire admission process.)</div>

          <div className="link_info">
          <Link
         to="/undergraduate/login" target="_blank" rel="noopener noreferrer"
        >
          CLICK HERE
        </Link>  to Log Back into Your Undergraduate Student Registration Account
        </div>
        </div>
      </div>
      <div className="each-item">
        <div className="item-heading">RETURNING STUDENTS</div>
        <div className="quick-links">
           <div className="key-info">
           Returning Students, click here to generate your registration fee payment invoice/transaction ID slip 
           </div>
        
           <div className="link_info">
          <Link
         to="/undergraduate/login" target="_blank" rel="noopener noreferrer"
        >
          CLICK HERE
        </Link>  to Log Back into Your Undergraduate Student Registration Account
        </div>
        </div>
      </div>
    </div>
  );
};

export default QuickLinks;