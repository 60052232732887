import { ApiService } from "../../../services/apiService";
import { routes } from "../../../services/paths";
import { handleRequestErrors } from "../../../shared/utils";

export const getPdfBlob = async (
  code: string,
  session: string,
  type: string
): Promise<Blob | any> => {
  try {
    const response = await ApiService.request({
      url: `${routes.VERIFY_QR_CODE}?code=${code}&session=${session}&type=${type}`,
      http_method: "GET",
      headers: { "X-Signature": routes.apiKey },
    });
    return response;
  } catch (err) {
    handleRequestErrors(err);
    return err;
  }
};

export const getExamCardConfirmationData = async (
  code: string,
  session: string,
  type: string,
  semester: string
): Promise<any> => {
  try {
    const response = await ApiService.request({
      url: `${routes.VERIFY_EXAM_CARD_QR_CODE}?code=${code}&session=${session}&type=${type}&semester=${semester}`,
      http_method: "GET",
      headers: { "X-Signature": routes.apiKey },
    });
    return response;
  } catch (err) {
    handleRequestErrors(err);
    return err;
  }
};
