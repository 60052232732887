import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { UndergraduateAdmissionStatusChecker } from "../../../../../shared/templates/admissions/undergraduate/index";
import { allowNumbersOnly } from "../../../../../shared/utils";
import { StepGuide } from "./step-guide";
import { DownloadModal } from "./download-modal";
import { uGradAdmissions } from "../../../../../redux/action/admissions/undergraduate";
import { appConstantsUGradAdmisssions } from "../../../../../redux/action-constants/admissions/undergraduate";

import { AlertMsg } from "../../../../../shared/components/alert-msg";

const printAcceptanceInvoice = async ({ pageProps, payload }: any) => {
  await pageProps.printMyAcceptanceInvoice(payload);
};

const PrintAcceptanceInvoice = ({ pageProps }: any) => {
  const [isDwModalShown, setShowDwModal] = useState<boolean>(false);
  let requestInfo = pageProps?.uGradPrintMyAcceptanceInvoiceRequest;
  let admissionData = pageProps?.admissionData;



  useEffect(() => {
    printAcceptanceInvoice({
      pageProps,
      payload: "CLEAR",
    });
  }, []);
  let checkValidationSchema = Yup.object().shape({
    email: admissionData?.acceptanceInvoiceEmail
      ? Yup.string().trim()
      : Yup.string()
          .trim()
          .email("Valid email is required")
          .required("Required"),
    registrationNumber: admissionData?.registrationNumber
      ? Yup.string()
      : Yup.string().required("Required"),
    phoneNumber: admissionData?.acceptanceInvoicePhoneNumber
      ? Yup.string()
      : Yup.string()
          .matches(/^\+?[0-9]{7,20}$/, "Phone number must be 8 digits or more")
          .required("Phone number is required"),
  });
  let initialValues: any = {
    email: admissionData?.acceptanceInvoiceEmail || "",
    phoneNumber: admissionData?.acceptanceInvoicePhoneNumber || "",
    registrationNumber: admissionData?.registrationNumber || "",
    studentId: admissionData?.studentId || "",
    fullName: admissionData?.fullName || "",
  };
  return (
    <div className="status_check_wrap">
      {isDwModalShown && (
        <DownloadModal
          downloadLink={requestInfo?.request_data?.response?.downloadUrl}
          isDwModalShown={isDwModalShown}
          setShowDwModal={setShowDwModal}
        />
      )}

      <div className="page_heading">
        Admission Acceptance Fees Transaction Slip {pageProps?.admissionData?.sessionData?.session} session
      </div>
      <StepGuide step={2} />
      <div className="other_info_wrap">
        <div className="provide_details ">
          <div className="with_form">
            {requestInfo.request_status !==
              appConstantsUGradAdmisssions?.GET_ACCEPTANCE_INVOICE_SUCCESS && (
              <Formik
                initialValues={initialValues}
                validationSchema={checkValidationSchema}
                onSubmit={async (values) => {
                  let { registrationNumber, phoneNumber, email, studentId } =
                    values;
                  let payload: any = {
                    registrationNumber,
                    phoneNumber,
                    email,
                    studentId,
                  };
                  await printAcceptanceInvoice({ pageProps, payload });
                }}
              >
                {(props: FormikProps<any>) => {
                  const {
                    values,
                    touched,
                    errors,
                    // handleBlur,
                    handleChange,
                    // isSubmitting,
                  } = props;

                  return (
                    <Form>
                      <div className="form-group">
                        <label className="label">Full Name </label>
                        <div
                          className={
                            errors.fullName && touched.fullName
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          <Field
                            value={values.fullName}
                            id="fullName"
                            name="fullName"
                            type="text"
                            disabled
                            onChange={() => {}}
                            placeholder=""
                            className={`input-wrap ${
                              errors.fullName && touched.fullName
                                ? "is-invalid-input "
                                : !errors.fullName && values.fullName !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="fullName"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                      <div className="form-group">
                        <label className="label">JAMB (UTME/DE) REG No </label>
                        <div
                          className={
                            errors.registrationNumber &&
                            touched.registrationNumber
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          <Field
                            value={values.registrationNumber}
                            id="registrationNumber"
                            name="registrationNumber"
                            type="text"
                            disabled
                            onChange={() => {}}
                            placeholder="Enter your JAMB (UTME/DE) Registration No here"
                            className={`input-wrap ${
                              errors.registrationNumber &&
                              touched.registrationNumber
                                ? "is-invalid-input "
                                : !errors.registrationNumber &&
                                  values.registrationNumber !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="registrationNumber"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                      <div className="form-group">
                        <label className="label">Phone Number : </label>
                        <div
                          className={
                            errors.phoneNumber && touched.phoneNumber
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          <Field
                            value={allowNumbersOnly(values.phoneNumber)}
                            id="phoneNumber"
                            name="phoneNumber"
                            type="text"
                            disabled={
                              admissionData?.acceptanceInvoicePhoneNumber !==
                              null
                            }
                            onChange={
                              admissionData?.acceptanceInvoicePhoneNumber !==
                                null
                                ? () => { }
                                : handleChange("phoneNumber")
                            }
                            placeholder="Enter your Phone Number"
                            className={`input-wrap ${
                              errors.phoneNumber && touched.phoneNumber
                                ? "is-invalid-input "
                                : !errors.phoneNumber &&
                                  values.phoneNumber !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="phoneNumber"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                      <div className="form-group">
                        <label className="label">Email Address : </label>
                        <div
                          className={
                            errors.email && touched.email
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          <Field
                            value={values.email}
                            id="email"
                            name="email"
                            type="text"
                            disabled={
                              admissionData?.acceptanceInvoiceEmail !== null
                            }
                            onChange={
                              admissionData?.acceptanceInvoiceEmail !== null
                                ? () => {}
                                : handleChange("email")
                            }
                            placeholder="Enter your Email Address"
                            className={`input-wrap ${
                              errors.email && touched.email
                                ? "is-invalid-input "
                                : !errors.email && values.email !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="email"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                      {requestInfo.request_status ===
                        appConstantsUGradAdmisssions?.GET_ACCEPTANCE_INVOICE_FAILURE && (
                        <AlertMsg
                          type="error"
                          message={requestInfo?.request_data.error}
                        />
                      )}
                      {admissionData?.hasGeneratedAcceptanceFeeInvoice && (
                        <button
                          disabled={requestInfo?.is_request_processing}
                          className="btn"
                        >
                          {" "}
                          {requestInfo?.is_request_processing
                            ? "Please wait..."
                            : "Re-Print Acceptance Fee Payment Instructions"}{" "}
                        </button>
                      )}

                      {!admissionData?.hasGeneratedAcceptanceFeeInvoice && (
                        <button
                          disabled={requestInfo?.is_request_processing}
                          className="btn"
                        >
                          {" "}
                          {requestInfo?.is_request_processing
                            ? "Please wait..."
                            : "Print Acceptance Fee Payment Instructions"}{" "}
                        </button>
                      )}

                      {/* {admissionData?.programme.toUpperCase() == "MEDICINE AND SURGERY" && admissionData?.hasPaidAcceptanceFee && (
                            <AlertMsg
                              type="error"
                              message="PLEASE CHECK BACK FOR SCHOOL FEES PAYMENT INSTRUCTIONS"
                            />
                          )} */}
                    </Form>
                  );
                }}
              </Formik>
            )}

            {requestInfo.request_status ===
              appConstantsUGradAdmisssions?.GET_ACCEPTANCE_INVOICE_SUCCESS && (
              <AlertMsg
                type="success"
                message="Kindly print your provisional admission acceptance fee invoice and proceed to any bank to make payment. After completing your payment reload this page and provide your UTME number to proceed to get school fees invoice"
              />
            )}
            {requestInfo.request_status ===
              appConstantsUGradAdmisssions?.GET_ACCEPTANCE_INVOICE_SUCCESS &&
              requestInfo?.request_data?.response?.downloadUrl && (
                <div className="alert-action">
                  Click here to{" "}
                  <span onClick={() => setShowDwModal(true)}>
                    download your admission acceptance invoice
                  </span>
                </div>
              )}

            <div className="nav_content">
              <div className="nav_back">
                <span
                  onClick={() => {
                    pageProps?.setShowAcceptance(false);
                    pageProps?.setShowAdmissionCongrats(true);
                  }}
                >
                  {" "}
                  Back
                </span>
              </div>

              {admissionData?.hasPaidAcceptanceFee ? (
                // {admissionData?.hasPaidAcceptanceFee && admissionData?.programme.toUpperCase() !== "MEDICINE AND SURGERY" ? (
                <div className="nav_back">
                  <span
                    onClick={() => {
                      pageProps?.setShowAcceptance(false);
                      pageProps?.setShowSchoolFeeInvoice(true);
                    }}
                  >
                    {" "}
                    Proceed
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrintAcceptanceInvoiceWrap = (pageProps: any) => {
  return (
    <UndergraduateAdmissionStatusChecker
      childComponent={<PrintAcceptanceInvoice pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  printMyAcceptanceInvoice: uGradAdmissions.PrintMyAcceptanceInvoice,
  
};
const mapStateToProps = (state: any) => ({
  uGradPrintMyAcceptanceInvoiceRequest:
    state.allDdmissionsReducers.uGradPrintMyAcceptanceInvoice,
    uGradCheckMyStatusRequest: state.allDdmissionsReducers.uGradCheckMyStatus,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintAcceptanceInvoiceWrap);
