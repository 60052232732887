import Modal from "react-bootstrap/Modal";
const ImageModal = ({
  show,
  setShow,
  documentType,
  setDocumentType,
  docs,
  setDocs,
}: any) => {
  return (
    <Modal
      className="image-modal"
      show={show}
      onHide={() => {
        setShow(false);
        setDocs([]);
        setDocumentType("");
      }}
    >
      <Modal.Body>
        <p>{documentType}</p>
        <div className="modal-images">
          {docs[0]?.length ? <img src={docs[0]} /> : null}
          {docs[1]?.length ? <img src={docs[1]} /> : null}
        </div>
        <button
          onClick={() => {
            setShow(false);
            setDocs([]);
            setDocumentType("");
          }}
        >
          Close
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
