import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UndergraduatePortal } from "../../../shared/templates/portal/undergraduate";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useWindowSize } from "../../../hooks/useWindowSize";
import { AlertMsg } from "../../../shared/components/alert-msg";

import { uGradPortal } from "../../../redux/action/portal/undergraduate";
import { appConstantsUGradOPortal } from "../../../redux/action-constants/portal/undergraduate";
import "./index.scss"




const changeStudentPassswordActionRequest = async ({ pageProps, payload }: any) => {

    await pageProps.changeStudentPassswordAction(payload);
}


const StudentForm = ({ pageProps }: any) => {
    const [showDefaultPwError, setShowDefaultPwError] = useState<boolean>(false);
    let requestInfo = pageProps.changePasswordRequest;
    // useEffect(() => {
    //     if (requestInfo.request_status == appConstantsUGradOPortal?.CHANGE_STUDENT_PASSWORD_SUCCESS) {
    //         setTimeout(() => {
    //             pageProps.logoutAction();
    //         }, 5000);

    //     }

    // }, [requestInfo?.request_status])
    let checkValidationSchema = Yup.object().shape({
        newPassword: Yup.string().required('Password is required').min(8, "Minimum of 8 Characters required"),
        confirmPassword: Yup.string().required('Required').when("newPassword", {
            is: (val: string | any[]) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("newPassword")],
                "Both password need to be the same"
            )
        })
    });
    let initialValues: any = {
        newPassword: "",
        confirmPassword: ""
    }

    let loggedAccountInfo: any = localStorage.getItem("stuXAuth") || "{}";
    loggedAccountInfo = JSON.parse(loggedAccountInfo);

    return (
        <div className="student_form">
            {requestInfo.request_status !== appConstantsUGradOPortal?.CHANGE_STUDENT_PASSWORD_SUCCESS &&
                <Formik
                    initialValues={initialValues}

                    validationSchema={checkValidationSchema}
                    onSubmit={async (values) => {
                        let { confirmPassword, newPassword } = values;
                        let payload: any = {
                            password: newPassword,
                            confirmPassword
                        }
                        if (newPassword == "123456789") {
                            setShowDefaultPwError(true)
                        } else {
                            setShowDefaultPwError(false)


                            await changeStudentPassswordActionRequest({ pageProps, payload })
                        }
                    }}
                >
                    {(props: FormikProps<any>) => {
                        const {
                            values,
                            touched,
                            errors,
                            // handleBlur,
                            handleChange,
                            // isSubmitting,
                        } = props;
                        return (
                            <Form>
                                {!loggedAccountInfo?.profile?.hasChangedDefaultPassword &&
                                    <AlertMsg type="error" message="You are required to update your password before getting access to your portal" />
                                }
                                <div className="form-group">
                                    <label htmlFor="newPassword">New Password</label>
                                    <div className={errors.newPassword && touched.newPassword ? "invalid-input-wrap" : ""}>
                                        <Field
                                            value={values.newPassword}
                                            id="newPassword"
                                            name="newPassword"
                                            type="password"
                                            onChange={handleChange('newPassword')}
                                            className={` ${errors.newPassword && touched.newPassword ?
                                                "is-invalid-input "
                                                : !errors.newPassword && values.newPassword !== "" ? "valid-input" : ""}`}
                                        />
                                    </div>
                                    <ErrorMessage name="newPassword" className="form-input-error" component="div" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="confirmPassword">Confirm Password</label>
                                    <div className={errors.confirmPassword && touched.confirmPassword ? "invalid-input-wrap" : ""}>
                                        <Field
                                            value={values.confirmPassword}
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            type="password"
                                            onChange={handleChange('confirmPassword')}
                                            className={` ${errors.confirmPassword && touched.confirmPassword ?
                                                "is-invalid-input "
                                                : !errors.confirmPassword && values.confirmPassword !== "" ? "valid-input" : ""}`}
                                        />
                                    </div>
                                    <ErrorMessage name="confirmPassword" className="form-input-error" component="div" />
                                </div>

                                {requestInfo.request_status === appConstantsUGradOPortal?.CHANGE_STUDENT_PASSWORD_FAILURE &&
                                    <AlertMsg type="error" message={requestInfo?.request_data.error} />
                                }
                                {
                                    (showDefaultPwError && values.newPassword=="123456789") &&
                                    <AlertMsg type="error" message="The password provided is not allowed" />
                                }
                                <div className="submit_formcta">
                                    <button
                                        disabled={requestInfo?.is_request_processing}
                                        className="btn shaded"
                                        type="submit">{requestInfo?.is_request_processing ? "Please wait..." : "Update Password"}</button>
                                </div>



                            </Form>
                        )
                    }}

                </Formik>
            }

            {requestInfo.request_status === appConstantsUGradOPortal?.CHANGE_STUDENT_PASSWORD_SUCCESS &&
                <AlertMsg type="success" message="Password successfully updated. You need to login with your new password" />
            }
        </div>


    )
}

const StudentPasswordSettings = ({ pageProps }: any) => {


    useEffect(() => {

        changeStudentPassswordActionRequest({
            pageProps,
            payload: "CLEAR"
        })


    }, [])


    return (
        <div className="student_password settings_wrap_content">
            <StudentForm pageProps={pageProps} />


        </div>
    )
}



const UpdateStudentPassword = (pageProps: any) => {


    return (
        <UndergraduatePortal
            title="Update your Password"
            childComponent={<StudentPasswordSettings pageProps={pageProps} />}
        />
    );



}

const mapDispatchToProps = {
    changeStudentPassswordAction: uGradPortal.ChangeStudentPasssword,
};
const mapStateToProps = (state: any) => ({
    changePasswordRequest: state.allUGradPortalReducers.ChangePasswordReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateStudentPassword);