import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AlertMsg } from "../../../../shared/components/alert-msg";
import { LoadingItem } from "../../../../shared/components/loading";
import Logo from "../../../../assets/logo2.png";
import PwSeen from "../../../../assets/pw-visible.png";
import PwHide from "../../../../assets/pw-hidden.png";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { uGradOnboarding } from "../../../../redux/action/onboarding/undergrad";
import { appConstantsUGradOnboarding } from "../../../../redux/action-constants/onboarding";

import "../index.scss";

const UnderGradUpdateNewPasswordRequest = async ({ pageProps, payload }: any) => {
  await pageProps.UnderGradUpdateNewPassword(payload);
};

const UnderGradVerifyForgotPwCodeRequest = async ({ pageProps, payload }: any) => {
  await pageProps.UnderGradVerifyForgotPwCode(payload);
};

const UGUpdatePassword = (pageProps: any) => {
  const [isPwShown, setShowPw] = useState<boolean>(false);
  const screenResolution: any = useWindowSize();
  const reloadVariable = localStorage.getItem("adRld");
  const { resetCode } = useParams();
  let yearTxt = new Date().getFullYear();

  useEffect(() => {
    if (!reloadVariable) {
      localStorage.setItem("adRld", JSON.stringify(true));
      window.location.reload();
    }
  }, [reloadVariable]);

  let requestInfo = pageProps?.uGradVerifyForgotPwCodeRequest;
  let resetRequestInfo = pageProps?.uGradUpdateNewPasswordRequest;

  useEffect(() => {
    let payload = `?resetCode=${resetCode}`
    UnderGradUpdateNewPasswordRequest({
      pageProps,
      payload: "CLEAR",
    });
    UnderGradVerifyForgotPwCodeRequest({
      pageProps,
      payload
    });
    // pageProps.logoutAction();
  }, []);


  let checkValidationSchema = Yup.object().shape({
    newPassword: Yup.string().required('Password is required').min(8, "Minimum of 8 Characters required"),
    confirmPassword: Yup.string().required('Required').when("newPassword", {
      is: (val: string | any[]) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Both password need to be the same"
      )
    })
  });
  let initialValues: any = {
    newPassword: "",
    confirmPassword: ""
  }

  return (
    <>
      <div className="onboarding_wrap reset">
        <div className="login-form-wrapper">
          <div className="onboarding_content splitted">
            {screenResolution?.width >= 767 && (
              <div className="each_side summary ipad-view">
                <div>
                  <div className="website_link">
                    {" "}
                    <a href="https://ibbu.edu.ng/">Main Website</a>{" "}
                  </div>
                  <div className="logo">
                    <a href="https://ibbu.edu.ng/">
                      <img src={Logo} alt="" />
                    </a>
                  </div>
                  <div className="school_name">
                    IBRAHIM BADAMASI BABANGIDA UNIVERSITY
                  </div>
                  <div className="student_type_txt">
                    FULL-TIME STUDENT <br /> RESET PASSWORD
                  </div>
                </div>
              </div>
            )}
            {screenResolution?.width <= 766 && (
              <div className="mobile_heading">
                <div className="logo">
                  <a href="https://ibbu.edu.ng/">
                    <img src={Logo} alt="" />
                  </a>
                </div>
                <div className="school_name">
                  IBRAHIM BADAMASI BABANGIDA UNIVERSITY
                </div>
                {/* <div className="student_type_txt">FULL-TIME  STUDENT <br /> SIGN IN</div> */}
              </div>
            )}
            <div className="each_side form_info">

              <div className="form_heading">UNDERGRADUATE PORTAL</div>
              <div className="form_heading">UPDATE PASSWORD</div>
              {pageProps?.uGradVerifyForgotPwCodeRequest?.request_status ==
                appConstantsUGradOnboarding?.VERFIY_PASSWORD_TOKEN_SUCCESS &&
                pageProps?.uGradUpdateNewPasswordRequest?.request_status !==
                appConstantsUGradOnboarding?.UPDATE_NEW_PASSWORD_SUCCESS &&
                <>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={checkValidationSchema}
                    onSubmit={async (values) => {
                      let { newPassword, confirmPassword } = values;
                      let payload= {
                        requestedBy:pageProps?.uGradVerifyForgotPwCodeRequest?.request_data?.response,
                        password: newPassword,
                        confirmPassword,
                        resetCode
                      };
                      await UnderGradUpdateNewPasswordRequest({ pageProps, payload });
                    }}
                  >
                    {(props: FormikProps<any>) => {
                      const {
                        values,
                        touched,
                        errors,
                        // handleBlur,
                        handleChange,
                        // isSubmitting,
                      } = props;
                      return (
                        <Form className="form-wrapper">
                          <div className="form-group">
                            <label htmlFor="newPassword">New Password</label>
                            <div className={errors.newPassword && touched.newPassword ? "invalid-input-wrap" : ""}>
                              <Field
                                value={values.newPassword}
                                id="newPassword"
                                name="newPassword"
                                type="password"
                                onChange={handleChange('newPassword')}
                                className={` ${errors.newPassword && touched.newPassword ?
                                  "is-invalid-input "
                                  : !errors.newPassword && values.newPassword !== "" ? "valid-input" : ""}`}
                              />
                            </div>
                            <ErrorMessage name="newPassword" className="form-input-error" component="div" />
                          </div>
                          <div className="form-group">
                            <label htmlFor="confirmPassword">Confirm Password</label>
                            <div className={errors.confirmPassword && touched.confirmPassword ? "invalid-input-wrap" : ""}>
                              <Field
                                value={values.confirmPassword}
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                onChange={handleChange('confirmPassword')}
                                className={` ${errors.confirmPassword && touched.confirmPassword ?
                                  "is-invalid-input "
                                  : !errors.confirmPassword && values.confirmPassword !== "" ? "valid-input" : ""}`}
                              />
                            </div>
                            <ErrorMessage name="confirmPassword" className="form-input-error" component="div" />
                          </div>

                          {pageProps?.uGradUpdateNewPasswordRequest?.request_status ===
                            appConstantsUGradOnboarding?.UPDATE_NEW_PASSWORD_FAILURE && (
                              <AlertMsg
                                type="error"
                                message={pageProps?.uGradUpdateNewPasswordRequest?.request_data.error}
                              />
                            )}
                          <div className="submit_cta">
                            <button
                              disabled={pageProps?.uGradUpdateNewPasswordRequest?.is_request_processing}
                              className="btn shaded"
                              type="submit"
                            >
                              {pageProps?.uGradUpdateNewPasswordRequest?.is_request_processing
                                ? "Please wait..."
                                : "Update Password"}
                            </button>
                          </div>
                          
                          {screenResolution?.width <= 766 && (
                            <div className="website_link">
                              {" "}
                              <a href="https://ibbu.edu.ng/">Main Website</a>{" "}
                            </div>
                          )}
                        </Form>
                      );
                    }}
                  </Formik>
                </>
              }
              {pageProps?.uGradVerifyForgotPwCodeRequest?.request_status ==
                appConstantsUGradOnboarding?.VERFIY_PASSWORD_TOKEN_PENDING &&
                <LoadingItem />
              }
              {pageProps?.uGradVerifyForgotPwCodeRequest?.request_status ==
                appConstantsUGradOnboarding?.VERFIY_PASSWORD_TOKEN_FAILURE &&
                <>
                  <AlertMsg
                    type="error"
                    message={pageProps?.uGradVerifyForgotPwCodeRequest?.request_data.error}
                  />
                  <div className="forgot_cta">
                    <Link to="/undergraduate/accounts/forgot-password">Go Back</Link>
                  </div>
                </>
              }
              {pageProps?.uGradUpdateNewPasswordRequest?.request_status ==
                appConstantsUGradOnboarding?.UPDATE_NEW_PASSWORD_SUCCESS &&
                <>
                  <AlertMsg
                    type="success"
                    message="Your Password has been successfully updated"
                  />
                  <div className="forgot_cta">
                    <Link to="/undergraduate/login">Sign In</Link>
                  </div>
                </>

              }
            </div>
          </div>

        </div>

        {screenResolution?.width >= 767 && (
          <div className="login-footer">
            <p> <span>&copy; {yearTxt}</span> IBB University. All rights reserved</p>
            <p>Developed by <a href="https://gigbadi.com.ng/" target="_blank" className="footer-link">Gigbadi</a></p>
          </div>
        )}

        {screenResolution?.width < 767 && (
          <div className="mobile-footer">
            <p>
              {" "}
              <span>&copy; {yearTxt}</span> IBB University. All rights reserved
            </p>
            <p>
              Developed by{" "}
              <Link
                to="https://gigbadi.com.ng/"
                target="_blank"
                className="footer-link"
              >
                Gigbadi
              </Link>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  UnderGradUpdateNewPassword: uGradOnboarding.UnderGradUpdateNewPassword,
  UnderGradVerifyForgotPwCode: uGradOnboarding.UnderGradVerifyForgotPwCode,

};
const mapStateToProps = (state: any) => ({
  uGradVerifyForgotPwCodeRequest: state.allOnboardingReducers.uGradVerifyForgotPwCode,
  uGradUpdateNewPasswordRequest: state.allOnboardingReducers.uGradUpdateNewPassword,
});
export default connect(mapStateToProps, mapDispatchToProps)(UGUpdatePassword);
