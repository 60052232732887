import { connect } from "react-redux";
import { useState, useEffect } from "react";

import { uGradAdmissions } from "../../../../../redux/action/admissions/undergraduate";
import { appConstantsUGradAdmisssions } from "../../../../../redux/action-constants/admissions/undergraduate";

import { UndergraduateAdmissionStatusChecker } from "../../../../../shared/templates/admissions/undergraduate/index";
import { StepGuide } from "./step-guide";
import { AlertMsg } from "../../../../../shared/components/alert-msg";
import { LoadingItem } from "../../../../../shared/components/loading";
const getAdmissionStatus = async ({ pageProps, payload }: any) => {
  await pageProps.getMyAdmissionStatus(payload);
};

const getCurrentSession = async ({ pageProps, payload }: any) => {
  await pageProps.getCurrentSession(payload);
};

const CheckCurrentStatus = ({ pageProps }: any) => {
  const [utmeNumber, setUtmeNumber] = useState<string>("");
  let checkMyStatus = pageProps?.uGradCheckMyStatusRequest;
  let checkSession = pageProps?.uGradCheckSessionRequest;
  useEffect(() => {
    getCurrentSession(
      { pageProps }
    )
  }, [])
  return (
    <div className="status_check_wrap">
      {checkSession?.request_status ===
        appConstantsUGradAdmisssions?.CHECK_CURRENT_SESSION_SUCCESS &&
        <>
          <div className="page_heading">Check Admission Status (UTME/DE) {checkSession?.request_data?.response?.session} </div>
          <StepGuide step={0} />
          <div className="other_info_wrap">
            <div className="provide_details">
              <div className="details_heading">
                ENTER YOUR JAMB REGISTRATION NUMBER TO CHECK YOUR ADMISSION STATUS *
              </div>
              <div>
                <input
                  value={utmeNumber}
                  id="utmeNumber"
                  name="utmeNumber"
                  onChange={(e: any) => {
                    // if (e.target.value.trim().length >= 1) {
                    setUtmeNumber(e.target.value.trim());
                    // }
                  }}
                  onKeyDown={(e: any) => {
                    let keyCode = e.code || e.key;
                    if (keyCode == "Enter") {
                      getAdmissionStatus({
                        pageProps,
                        payload: {
                          registrationNumber: utmeNumber,
                        },
                      });
                    }
                  }}
                  type="text"
                  placeholder="Enter your JAMB (UTME/DE) Registration No"
                />
              </div>

              <div className="check_adm_error">
                {checkMyStatus.request_status ===
                  appConstantsUGradAdmisssions?.CHECK_ADMISSION_PROCESS_STATUS_FAILURE && (
                    <AlertMsg
                      type="error"
                      message={checkMyStatus?.request_data.error}
                    />
                  )}
              </div>

              <button
                onClick={() => {
                  getAdmissionStatus({
                    pageProps,
                    payload: {
                      registrationNumber: utmeNumber,
                    },
                  });
                }}
                disabled={
                  utmeNumber.length < 4 || checkMyStatus?.is_request_processing
                }
                className="btn"
              >
                {checkMyStatus?.is_request_processing
                  ? "Checking..."
                  : " Check Admission Status"}{" "}
              </button>
            </div>
            <div className="all_steps_info">
              <div className="heading-txt">
                Admission Status Checking Guidelines/Instructions:
              </div>
              <ul>
                <li>
                  The process consists of 4 steps, which are illustrated in the icons above.
                </li>
                <li>
                  Start by entering your JAMB (UTME/DE) registration number to check if you have been offered admission.
                </li>
                <li>
                  If admission is granted, click the "Proceed" button to generate an invoice. This invoice contains the payment instructions for the acceptance fee.
                </li>
                <li>
                  After paying the acceptance fee, wait 30 minutes to 1 hour, then return to the portal to confirm if the payment has been received.
                </li>
                <li>
                  Provide a valid email address to receive updates and notifications about your payment status.
                </li>
                <li>
                  Once your acceptance fee payment is confirmed, log back into the portal and enter your JAMB registration number. The system will verify the payment and move you to the next step, where you can generate an invoice with instructions for paying your school fees.
                </li>
                <li>
                  At this stage, ensure you provide a valid phone number. This phone number will be used to create your Undergraduate Portal account. Also, carefully read the instructions on the page.
                </li>
                <li>
                  After paying the school fees, return to the portal, enter your JAMB (UTME/DE) registration number. The system will then guide you to the final step, where you can print your provisional admission letter. This letter will include your matriculation number.
                </li>
                <li>
                  Note: Each step provides additional information. Be sure to read and follow the instructions carefully.
                </li>
                <li>
                  Finally, visit the Undergraduate Portal at <a href="https://ibbuportals.ibbu.edu.ng" target="_blank">https://ibbuportals.ibbu.edu.ng</a>. Log in using your matriculation number and the phone number you provided earlier.
                </li>
              </ul>
            </div>
            <div className="steps_help_info"></div>
          </div>
        </>
      }
      {checkSession?.request_status ===
        appConstantsUGradAdmisssions?.CHECK_CURRENT_SESSION_PENDING &&
        <LoadingItem />
      }
      {checkSession?.request_status ===
        appConstantsUGradAdmisssions?.CHECK_CURRENT_SESSION_FAILURE &&
        <AlertMsg
          type="error"
          message={checkSession?.request_data.error}
        />
      }
    </div>
  );
};

const CheckCurrentStatusWrap = (pageProps: any) => {
  return (
    <UndergraduateAdmissionStatusChecker
      childComponent={<CheckCurrentStatus pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  getMyAdmissionStatus: uGradAdmissions.CheckMyAdmissionStatus,
  getCurrentSession: uGradAdmissions.CheckCurrentSession,

};
const mapStateToProps = (state: any) => ({
  uGradCheckMyStatusRequest: state.allDdmissionsReducers.uGradCheckMyStatus,
  uGradCheckSessionRequest: state.allDdmissionsReducers.uGradCheckSession,

});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckCurrentStatusWrap);
