import { ApiService } from "../../../../services/apiService";
import { routes } from "../../../../services/paths";
import { handleRequestErrors } from "../../../../shared/utils";

export const getCourseRegistrationDetails = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.COURSE_REGISTRATION_SESSION_HISTORY}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const PrintCourseRegistration = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.PRINT_COURSE_REGISTRATION_HISTORY}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};
