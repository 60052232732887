import Table from "react-bootstrap/Table";
import "./index.scss";
import BlueEye from "../../../assets/Blue-Eye.png";
import { useSelector } from "react-redux";
import { formatDate } from "./Tabs";

const DocumentsHistory = ({ setShow, setDocs, setDocumentType }: any) => {
  const { documentHistory } = useSelector((state: any) => state.eDocumentation);
  return (
    <div className="clearance-table-container">
      <Table bordered hover className="clearance-request-table">
        <thead>
          <tr>
            <th>DOCUMENT</th>
            <th>SESSION</th>
            <th>DATE</th>
            <th>DOCUMENT DISPLAY</th>
            <th>VIEW</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {documentHistory?.length ? (
            documentHistory?.map((request: any, index: any) => {
              return (
                <tr key={index}>
                  <td>{request?.documentType}</td>
                  <td>{request?.session}</td>
                  <td>{formatDate(request?.dateUploaded)}</td>
                  <td className="document-display">
                    {request?.downloadUrlFront ? (
                      <img
                        src={request?.downloadUrlFront}
                        alt="Front page"
                        className="table-image"
                      />
                    ) : null}{" "}
                    {request?.downloadUrlBack ? (
                      <img
                        src={request?.downloadUrlBack}
                        alt="Back page"
                        className="table-image"
                      />
                    ) : null}
                  </td>
                  <td>
                    <img
                      src={BlueEye}
                      alt="Eye icon"
                      className="eye-icon"
                      onClick={() => {
                        setDocs([
                          request?.downloadUrlFront,
                          request?.downloadUrlBack,
                        ]);
                        setDocumentType(request?.documentType);
                        setShow(true);
                      }}
                    />
                  </td>
                  <td>{request?.status}</td>
                </tr>
              );
            })
          ) : (
            <p className="no-results">No results...</p>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default DocumentsHistory;
