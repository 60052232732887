import React from "react";
import { Link } from "react-router-dom";
const Instructions = () => {
    return (
        <div className="main-content">
            <p>
                All students are to take note of the following before, during and after the submission of their registration.
            </p>
            <div className="must-read">
                IMPORTANT !!! During your BIO-DATA update, Please kindly ensure you provide accurate data as You cannot EDIT them Later.
            </div>



            <div className="steps">
                <ul>
                    <li>
                        Generate your registration transaction ID
                        from the portal and make the required payment via any of the
                        designated payment points/banks (Make sure you have your Transaction ID before you begin).
                    </li>
                    <li>
                        Before you proceed, your account must activated on the portal.
                        Also keep in mind, your
                        <div> (i) Login Matric Number</div>
                        <div>(ii) Login password and</div>
                        <div>
                            (iii) Valid e-mail address for correspondence. Your picture to be uploaded MUST conform with the following:
                            <ul className="itemize">
                                <li> * Jpeg format ONLY</li>
                                <li>* Less than 30KB</li>
                                <li>* Exactly 150px by 150px</li>
                                <li>* Recent Photograph</li>
                                <li>* Of good quality</li>
                                <li>* Of plain white background</li>
                            </ul>
                        </div>

                    </li>
                    <li>
                        Log onto the portal or <Link
                            to="/undergraduate/login" target="_blank" rel="noopener noreferrer"
                        >
                            CLICK HERE TO BEGIN YOUR ONLINE REGISTRATION.
                        </Link>
                    </li>
                    <li> Enter your MATRICULATION Number and password are correct.</li>
                    <li> If successful, you will be redirected to your DASHBOARD.</li>
                    <li> You must complete all necessary payments before you can register courses for the Session.</li>
                    <li> To login into your account, 
                        <Link
                            to="/undergraduate/login" target="_blank" rel="noopener noreferrer"
                        >
                             CLICK HERE TO LOG IN.
                        </Link>

                    </li>
                </ul>


            </div>
        </div>
    );
};

export default Instructions;