import { appConstantsUGradOPortal } from "../../action-constants/portal/undergraduate";

let userLogged = localStorage.getItem('stuXAuth') || "{}";
let user = JSON.parse(userLogged);
const initialState = Object.keys(user).length >= 1 ? { loggedIn: true, user } : { loggedIn: false };




export const UGradProfileReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.GET_PROFILE_DATA_PENDING:
            return {
                request_status: appConstantsUGradOPortal.GET_PROFILE_DATA_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.GET_PROFILE_DATA_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.GET_PROFILE_DATA_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GET_PROFILE_DATA_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.GET_PROFILE_DATA_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GET_PROFILE_DATA_RESET:
            return {
                request_status: appConstantsUGradOPortal.GET_PROFILE_DATA_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            // return {} 
            return {...state} 
    }
}

export const UGradGenerateAcceptanceFeeReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_PENDING:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_RESET:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_ACCEPTANCEFEE_RECEIPT_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {} 
            // return {...state} 
    }
}

export const UGradGstFeeReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.GENERATE_GSTFEE_PENDING:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_GSTFEE_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_GSTFEE_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_GSTFEE_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_GSTFEE_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_GSTFEE_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_GSTFEE_RESET:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_GSTFEE_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {} 
            // return {...state} 
    }
}

export const UGradGstNanissReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.GENERATE_NANISS_PENDING:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_NANISS_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_NANISS_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_NANISS_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_NANISS_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_NANISS_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_NANISS_RESET:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_NANISS_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {} 
            // return {...state} 
    }
}

export const UGradSchoolFeeReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.GENERATE_SCHOOLFEE_PENDING:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_SCHOOLFEE_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_SCHOOLFEE_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_SCHOOLFEE_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_SCHOOLFEE_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_SCHOOLFEE_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_SCHOOLFEE_RESET:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_SCHOOLFEE_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {} 
            // return {...state} 
    }
}

export const UGradSugFeeReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.GENERATE_SUGFEE_PENDING:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_SUGFEE_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_SUGFEE_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_SUGFEE_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_SUGFEE_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_SUGFEE_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_SUGFEE_RESET:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_SUGFEE_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {} 
            // return {...state} 
    }
}

export const UGradFacultyFeeReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.GENERATE_FACULTYFEE_PENDING:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_FACULTYFEE_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_FACULTYFEE_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_FACULTYFEE_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_FACULTYFEE_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_FACULTYFEE_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_FACULTYFEE_RESET:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_FACULTYFEE_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            // return {...state} 
            return {} 
    }
}

export const UGradEntrepreneurFeeReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.GENERATE_ENT_FEE_PENDING:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_ENT_FEE_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_ENT_FEE_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_ENT_FEE_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_ENT_FEE_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_ENT_FEE_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GENERATE_ENT_FEE_RESET:
            return {
                request_status: appConstantsUGradOPortal.GENERATE_ENT_FEE_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {}
            // return {...state} 
    }
}

export const UGradLoadPresetCoursesReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.UGRAD_LOAD_PRESET_COURSES_PENDING:
            return {
                request_status: appConstantsUGradOPortal.UGRAD_LOAD_PRESET_COURSES_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.UGRAD_LOAD_PRESET_COURSES_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.UGRAD_LOAD_PRESET_COURSES_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.UGRAD_LOAD_PRESET_COURSES_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.UGRAD_LOAD_PRESET_COURSES_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.UGRAD_LOAD_PRESET_COURSES_RESET:
            return {
                request_status: appConstantsUGradOPortal.UGRAD_LOAD_PRESET_COURSES_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const UGradSavePresetCoursesReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.SAVE_STUDENT_COURSES_REG_PENDING:
            return {
                request_status: appConstantsUGradOPortal.SAVE_STUDENT_COURSES_REG_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.SAVE_STUDENT_COURSES_REG_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.SAVE_STUDENT_COURSES_REG_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.SAVE_STUDENT_COURSES_REG_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.SAVE_STUDENT_COURSES_REG_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.SAVE_STUDENT_COURSES_REG_RESET:
            return {
                request_status: appConstantsUGradOPortal.SAVE_STUDENT_COURSES_REG_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {}
            // return {...state} 
    }
}

export const UnderGradDeletePresetCourseReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.DELETE_PRESET_COURSE_REG_PENDING:
            return {
                request_status: appConstantsUGradOPortal.DELETE_PRESET_COURSE_REG_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.DELETE_PRESET_COURSE_REG_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.DELETE_PRESET_COURSE_REG_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.DELETE_PRESET_COURSE_REG_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.DELETE_PRESET_COURSE_REG_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.DELETE_PRESET_COURSE_REG_RESET:
            return {
                request_status: appConstantsUGradOPortal.DELETE_PRESET_COURSE_REG_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {}
            // return {...state} 
    }
}

export const UnderGradPrintCourseReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.PRINT_COURSE_REG_PENDING:
            return {
                request_status: appConstantsUGradOPortal.PRINT_COURSE_REG_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.PRINT_COURSE_REG_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.PRINT_COURSE_REG_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.PRINT_COURSE_REG_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.PRINT_COURSE_REG_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.PRINT_COURSE_REG_RESET:
            return {
                request_status: appConstantsUGradOPortal.PRINT_COURSE_REG_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {}
            // return {...state} 
    }
}

export const UnderGradPrintExamCardReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.PRINT_EXAM_CARD_PENDING:
            return {
                request_status: appConstantsUGradOPortal.PRINT_EXAM_CARD_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.PRINT_EXAM_CARD_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.PRINT_EXAM_CARD_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.PRINT_EXAM_CARD_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.PRINT_EXAM_CARD_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.PRINT_EXAM_CARD_RESET:
            return {
                request_status: appConstantsUGradOPortal.PRINT_EXAM_CARD_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            // return {}
            return {...state} 
    }
}

export const UnderGradGetBioDataReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.GET_STUDENT_BIO_DATA_PENDING:
            return {
                request_status: appConstantsUGradOPortal.GET_STUDENT_BIO_DATA_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.GET_STUDENT_BIO_DATA_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.GET_STUDENT_BIO_DATA_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GET_STUDENT_BIO_DATA_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.GET_STUDENT_BIO_DATA_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.GET_STUDENT_BIO_DATA_RESET:
            return {
                request_status: appConstantsUGradOPortal.GET_STUDENT_BIO_DATA_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            // return {}
            return {...state} 
    }
}

export const UnderGradPrintBioDataReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.PRINT_STUDENT_BIO_DATA_PENDING:
            return {
                request_status: appConstantsUGradOPortal.PRINT_STUDENT_BIO_DATA_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.PRINT_STUDENT_BIO_DATA_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.PRINT_STUDENT_BIO_DATA_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.PRINT_STUDENT_BIO_DATA_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.PRINT_STUDENT_BIO_DATA_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.PRINT_STUDENT_BIO_DATA_RESET:
            return {
                request_status: appConstantsUGradOPortal.PRINT_STUDENT_BIO_DATA_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            // return {}
            return {...state} 
    }
}

export const UnderGradUpdateBioDataReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_PENDING:
            return {
                request_status: appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_RESET:
            return {
                request_status: appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            // return {}
            return {...state} 
    }
}

export const ChangePasswordReducer = (state=initialState, action:any)=>{
   
    switch (action.type) {
        case appConstantsUGradOPortal.CHANGE_STUDENT_PASSWORD_PENDING:
            return {
                request_status: appConstantsUGradOPortal.CHANGE_STUDENT_PASSWORD_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstantsUGradOPortal.CHANGE_STUDENT_PASSWORD_SUCCESS:
            return {
                request_status: appConstantsUGradOPortal.CHANGE_STUDENT_PASSWORD_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.CHANGE_STUDENT_PASSWORD_FAILURE:
            return {
                request_status: appConstantsUGradOPortal.CHANGE_STUDENT_PASSWORD_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstantsUGradOPortal.CHANGE_STUDENT_PASSWORD_RESET:
            return {
                request_status: appConstantsUGradOPortal.CHANGE_STUDENT_PASSWORD_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            // return {}
            return {...state} 
    }
}