import { Modal, Spinner } from "react-bootstrap";
import "./index.scss";

const Terms = ({ show, setShow, handleSubmitBooking, loading }: any) => {
  return (
    <Modal
      size="lg"
      className="terms-modal"
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Body>
        <div className="terms-container">
          <div className="terms">
            <h4>Terms and Conditions</h4>
            <ol className="terms-ol">
              <li>
                By clicking on the Accept button, you agree to the terms and
                conditions of the accommodation. At any moment in time, your
                application can be terminated if you violate any condition or
                hostel rules and regulations.
              </li>
              <li>
                If payment is not made within 48 hours of booking, your hostel 
                reservation will be automatically canceled.
              </li>
              <li>
                At any point in time, if it comes to our notice that wrong
                information has been provided by you, your bed space will be
                revoked without any refund.
              </li>
              <li>
                {" "}
                Ibrahim Badamasi Babangida University is not under any
                obligation to provide all students with hostel accommodation.
                Allocation of bed space in halls of residence is, therefore, a
                privilege and not a right.
              </li>
              <li>
                Allocation of bed spaces to eligible students is on the
                principle of first come, first served.
              </li>
              <li>
                Only fresh and final-year students may be eligible for bed
                space. In other words, while the university makes every effort
                to ensure that most students enjoy hostel accommodation, it
                should be realized that no student has a statutory right to
                university accommodation.
              </li>
              <li>
                Students allocated bed spaces are entitled to the benefits of
                the facilities in the hostels.
              </li>
              <li>
                Any student found occupying a bed space illegally or
                accommodating a 'squatter' at any point in time will be ejected
                and will face disciplinary actions. Punishment may range from
                ejection and rustication to expulsion.
              </li>
              <li>
                Any bed space or room allocation made cannot be altered, and
                students must remain where they are assigned for the period of
                the session under review.
              </li>
              <li>
                Acceptance of a place in the hostels means acceptance of all
                existing rules and regulations that may from time to time be
                drawn up to facilitate the management of the hostels.
              </li>
              <li>
                Failure to comply with hostel regulations may lead to the
                withdrawal of hostel allocation and other appropriate
                punishments.
              </li>
              <li>
                Hall administrators are authorized to make supplementary rules
                which they may consider necessary for the smooth running of the
                hostels, but they must seek the permission of the Dean, Student
                Affairs before implementation.
              </li>
              <li>
                The sale or consumption of alcohol or any intoxicant is strictly
                prohibited. Violation attracts expulsion.
              </li>
              <li>
                Smoking of cigarettes in public places such as hostel rooms,
                common rooms, classrooms, library, administrative offices,
                cafeteria, practical laboratories, etc., is strictly prohibited.
                Violation attracts suspension.
              </li>
              <li>
                Smoking of Indian hemp is strictly prohibited. Violation
                attracts expulsion.
              </li>
              <li>
                {" "}
                Fighting, quarrelling, threats of any kind, open religious
                preaching, and loud music are not allowed in the hostels and
                their environs. Violation attracts rustication.
              </li>
            </ol>
          </div>
          <div className="buttons">
            <button onClick={handleSubmitBooking} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : "Accept"}
            </button>
            <button onClick={() => setShow(false)} disabled={loading}>
              Decline
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Terms;
