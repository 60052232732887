import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { UndergraduateAdmissionStatusChecker } from "../../../../../shared/templates/admissions/undergraduate/index";
import { StepGuide } from "./step-guide"
import { DownloadModal } from "./download-modal"
import { uGradAdmissions } from "../../../../../redux/action/admissions/undergraduate"
import { appConstantsUGradAdmisssions } from "../../../../../redux/action-constants/admissions/undergraduate"

import { AlertMsg } from "../../../../../shared/components/alert-msg"


const printMyAdmissionLetter = async ({ pageProps, payload }: any) => {

    await pageProps.printMyAdmissionLetter(payload);
}

const PrintAdmissionLetter = ({ pageProps }: any) => {
    const [isDwModalShown, setShowDwModal] = useState<boolean>(false)
    let requestInfo = pageProps?.uGradPrintMyAdmissionLetterRequest
    let admissionData = pageProps?.admissionData

    useEffect(() => {


        printMyAdmissionLetter({
            pageProps,
            payload: "CLEAR"
        })


    }, [])

    let checkValidationSchema = Yup.object().shape({
        email: Yup.string().trim()
            .email('Valid email is required')
            .required('Required'),
        registrationNumber: Yup.string()
            .required('Required'),
        matricNumber: Yup.string()
            .required('Required'),
        phoneNumber: Yup.string()
            .required('Required'),

    });
    let initialValues: any = { email: "", phoneNumber: "", registrationNumber: "", matricNumber: "" }


    return (
        <div className="status_check_wrap">
            {isDwModalShown &&
                <DownloadModal downloadLink={requestInfo?.request_data?.response?.downloadUrl} isDwModalShown={isDwModalShown} setShowDwModal={setShowDwModal} />

            }
            <div className="page_heading"> PROVISIONAL ADMISSION INTO UNDERGRADUATE DEGREE CERTIFICATE {pageProps?.admissionData?.sessionData?.session} session</div>
            <StepGuide step={4} />
            <div className="other_info_wrap with-br">

                <div className="status_heading for_admission">
                    If you have made your registration fee payment successfully, click the button below to print your provisional admission letter and then proceed to your faculty for screening and documentation.
                </div>
                <div className="admission_info">
                    <div>This is subject to meeting the following requirements:</div>
                    <div>
                        You MUST ensure that you complete the registration process within the stipulated period. For the purpose of documentation you are required
                        to bring along the under listed items with three (3) photocopies of each
                    </div>
                    <div>
                        <ul>
                            <li>JAMB Admission Letter.</li>
                            <li>Original Certificate Of Educational Qualifications.</li>
                            <li>Original Birth Certificate or Statutory Declaration Of Age.</li>
                            <li>Eight Recent Passport Photographs.</li>
                            <li>Letter Of Attestation Of Good Behavior from Parents/Guardian.</li>
                            <li>Local Government Identification Letter.</li>
                        </ul>
                    </div>
                </div>
                <div className="provide_details without_border">
                    <div className="with_form">
                        {requestInfo?.request_status !== appConstantsUGradAdmisssions?.GET_ADMISSION_LETTER_SUCCESS &&

                            <>
                                {requestInfo.request_status === appConstantsUGradAdmisssions?.GET_ADMISSION_LETTER_FAILURE &&
                                    <AlertMsg type="error" message={requestInfo?.request_data.error} />
                                }
                                {admissionData?.hasGeneratedAdmissionLetter &&
                                    <button
                                        disabled={requestInfo?.is_request_processing}
                                        onClick={() => {
                                            let payload = {
                                                registrationNumber: admissionData?.registrationNumber
                                            }
                                            printMyAdmissionLetter({ pageProps, payload })
                                        }}
                                        className="btn"> {requestInfo?.is_request_processing ? "Please wait..." : "Re-Print Provisional Admission Letter"}  </button>
                                }

                                {!admissionData?.hasGeneratedAdmissionLetter &&
                                    <button
                                        disabled={requestInfo?.is_request_processing}
                                        onClick={() => {
                                            let payload = {
                                                registrationNumber: admissionData?.registrationNumber
                                            }
                                            printMyAdmissionLetter({ pageProps, payload })
                                        }}
                                        className="btn">  {requestInfo?.is_request_processing ? "Please wait..." : "Print Provisional Admission Letter"}  </button>
                                }

                            </>
                        }

                        {requestInfo.request_status === appConstantsUGradAdmisssions?.GET_ADMISSION_LETTER_SUCCESS &&
                            <AlertMsg type="success" message="Kindly print your provisional admission letter." />
                        }
                        {requestInfo?.request_status === appConstantsUGradAdmisssions?.GET_ADMISSION_LETTER_SUCCESS && requestInfo?.request_data?.response?.downloadUrl &&
                            <div className="alert-action">Click here to <span onClick={() => setShowDwModal(true)}>download your provisional admission letter</span> </div>

                        }
                        <div className="nav_back">
                            <span onClick={() => {
                                pageProps?.setShowDownloadAdmissionLetter(false)
                                pageProps?.setShowSchoolFeeInvoice(true)
                            }}> Back</span>
                        </div>


                    </div>
                </div>

                {/* <div className="proceed_wrap admission">
                    <button className="btn">Download Admission Letter</button>
                </div> */}
            </div>
        </div>
    )
}



const PrintAdmissionLetterWrap = (pageProps: any) => {
    return (
        <UndergraduateAdmissionStatusChecker childComponent={<PrintAdmissionLetter pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    printMyAdmissionLetter: uGradAdmissions.PrintMyAdmissionLetter,
};
const mapStateToProps = (state: any) => ({
    uGradPrintMyAdmissionLetterRequest: state.allDdmissionsReducers.uGradPrintMyAdmissionLetter,
})
export default connect(mapStateToProps, mapDispatchToProps)(PrintAdmissionLetterWrap);