import { combineReducers } from "redux";

import {
    admissionsReducers
} from "./admissions/admissions.reducer"
import {
    onboardingReducers
} from "./onboarding/onboarding.reducer"

import {
    portalReducers
} from "./portal/portal.reducer"
import eDocumentation from "./edocumentation";
const rootReducer = (state: any, action: any) => {
    // if (action.type === authConstants.LOGOUT) {
    //     state = undefined;
    // }
    return appReducer(state, action);
};

const allDdmissionsReducers = combineReducers({
    uGradCheckSession: admissionsReducers.uGradGetSessionReducer,
    uGradCheckMyStatus: admissionsReducers.uGradCheckAdmissionStatusReducer,
    uGradPrintMyAcceptanceInvoice: admissionsReducers.uGradPrintMyAcceptanceInvoiceReducer,
    uGradPrintMySchoolFeeInvoice: admissionsReducers.uGradPrintMySchoolFeeInvoiceReducer,
    uGradPrintMyAdmissionLetter: admissionsReducers.uGradPrintMyAdmissionLetterReducer
})


const allOnboardingReducers = combineReducers({
    uGradLogin: onboardingReducers.uGradLoginReducer,
    uGradForgotPw: onboardingReducers.uGradForgotPwReducer,
    uGradVerifyForgotPwCode: onboardingReducers.uGradVerifyForgotPwCodeReducer,
    uGradUpdateNewPassword: onboardingReducers.uGradUpdateNewPasswordeReducer,
})

const allUGradPortalReducers = combineReducers({
    UGradProfile: portalReducers.UGradProfileReducer,
    UGradGenerateAcceptanceFee: portalReducers.UGradGenerateAcceptanceFeeReducer,
    UGradGstFee: portalReducers.UGradGstFeeReducer,
    UGradNanissFee: portalReducers.UGradGstNanissReducer,
    UGradSchoolFee: portalReducers.UGradSchoolFeeReducer,
    UGradSugFee: portalReducers.UGradSugFeeReducer,
    UGradFacultyFee: portalReducers.UGradFacultyFeeReducer,
    UGradEntrepreneurFee: portalReducers.UGradEntrepreneurFeeReducer,
    UGradLoadPresetCourses: portalReducers.UGradLoadPresetCoursesReducer,
    UGradSavePresetCourses: portalReducers.UGradSavePresetCoursesReducer,
    UnderGradDeletePresetCourse: portalReducers.UnderGradDeletePresetCourseReducer,
    UnderGradPrintCourse: portalReducers.UnderGradPrintCourseReducer,
    UnderGradPrintExamCard: portalReducers.UnderGradPrintExamCardReducer,

    
    UnderGradGetBioData: portalReducers.UnderGradGetBioDataReducer,
    UnderGradPrintBioData: portalReducers.UnderGradPrintBioDataReducer,
    UnderGradUpdateBioData: portalReducers.UnderGradUpdateBioDataReducer,
    ChangePasswordReducer: portalReducers.ChangePasswordReducer,
})


const appReducer = combineReducers({
    allDdmissionsReducers,
    allOnboardingReducers,
    allUGradPortalReducers,
    eDocumentation
});

export default rootReducer;