import React, { useRef } from "react";
import Cloud from "../../../assets/cloud.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getClearanceRequests,
  uploadingEDoc,
  uplodEDocumentationDocs,
} from "../../../redux/action/e-documentation";
import { toast } from "react-toastify";

const UploadFile = ({
  pageSide,
  disabled,
  uploadPayload,
  thumbnail,
  setThumbnail,
  activeSide,
  setActiveSide,
  clearanceRequestBody,
}: any) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { uploadingDoc: loading } = useSelector(
    (state: any) => state.eDocumentation
  );
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    setActiveSide(pageSide);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 204800) {
        return toast.error("Max file size is 200kb");
      }
      const objValues = {
        ...uploadPayload,
      };
      const formData = new FormData();
      formData.append("file", file);
      for (const key in objValues) {
        if (Object.hasOwnProperty.call(objValues, key)) {
          formData.append(key, objValues[key]);
        }
      }

      const res = await dispatch(uplodEDocumentationDocs(formData, pageSide));
      if (res && res?.data) {
        toast.success("File uploaded successfully");
        setThumbnail((prevState: any) => ({
          ...prevState,
          [pageSide]: res?.data,
        }));
        dispatch(getClearanceRequests(clearanceRequestBody));
      }
      dispatch(uploadingEDoc(false));
    }
  };
  return (
    <div className="upload">
      {thumbnail[pageSide] ? (
        <img
          src={thumbnail[pageSide]}
          alt="Thumbnail"
          className="thumbnail-image"
        />
      ) : (
        <>
          <img src={Cloud} alt="" />
          <strong>Select {pageSide} Page</strong>
          <span>or drop document here</span>
          <p>JPG or JPEG</p>
          <p>Less than 200kb</p>
        </>
      )}
      <input
        type="file"
        accept=".jpeg,.jpg"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleFileChange(e)
        }
        onClick={(event) => ((event.target as HTMLInputElement).value = "")}
        disabled={disabled}
      />{" "}
      <button
        className="select-file-button"
        onClick={handleButtonClick}
        disabled={disabled || loading}
      >
        {loading && pageSide === activeSide
          ? "Uploading..."
          : thumbnail[pageSide]
          ? "Change file"
          : "Select File"}
      </button>
    </div>
  );
};

export default UploadFile;
