import { Block, Room } from "./hostelBooking/SiteList";

const Site = ({
  site,
  selectedRoom,
  selectedSite,
  onRoomSelect,
  selectedBlock,
  handleBlockChange,
  handleProceed,
  inputError,
  selectedSiteIndex,
  siteIndex,
}: any) => {
  const handleRoomChange = (event: any) => {
    const selectedRoom = event.target.value;
    onRoomSelect(selectedRoom);
  };
  const blockNameById = site?.blocks.find(
    (block: any) => block?.blockName === selectedBlock
  );

  return (
    <div className="site">
      <p>Site: {site?.siteName}</p>
      <select
        className="rooms-dropdown"
        value={selectedSiteIndex === siteIndex ? blockNameById : ""}
        onChange={(e) => handleBlockChange(e, site?.siteName, siteIndex)}
        style={{ marginBottom: "15px" }}
      >
        <option value="">Select block</option>
        {site?.blocks.map((block: Block) => (
          <option value={block?.blockUniqueId} key={block?.blockUniqueId}>
            {block?.blockName}
          </option>
        ))}
      </select>
      <select
        className="rooms-dropdown"
        value={selectedSiteIndex === siteIndex ? selectedRoom : ""}
        onChange={handleRoomChange}
        disabled={selectedSite !== site?.siteName}
      >
        <option value="">Select a room</option>
        {selectedBlock &&
          site?.blocks
            .find(
              (hostelBlock: any) => hostelBlock?.blockUniqueId === selectedBlock
            )
            ?.rooms?.map((room: Room) => (
              <option value={room?.roomUniqueId} key={room?.roomUniqueId}>
                {room?.roomName}
              </option>
            ))}
      </select>
      {inputError && selectedSiteIndex === siteIndex ? (
        <p className="input-error">Select block and room.</p>
      ) : null}
      <button
        className="btn"
        onClick={() => handleProceed(siteIndex)}
        disabled={selectedSite !== site?.siteName}
      >
        Proceed
      </button>
    </div>
  );
};

export default Site;
