import {
    uGradLoginReducer,
    uGradForgotPwReducer,
    uGradVerifyForgotPwCodeReducer,
    uGradUpdateNewPasswordeReducer 
} from "./ugrad-onboarding"



export const onboardingReducers = {
    uGradLoginReducer,
    uGradForgotPwReducer,
    uGradVerifyForgotPwCodeReducer,
    uGradUpdateNewPasswordeReducer
}