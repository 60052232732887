import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UndergraduatePortal } from "../../../shared/templates/portal/undergraduate";
import { StatesandLGA, getStates, getStateLgas } from "../../../shared/states";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { AlertMsg } from "../../../shared/components/alert-msg";
import { LoadingItem } from "../../../shared/components/loading";
import { uGradPortal } from "../../../redux/action/portal/undergraduate";
import { appConstantsUGradOPortal } from "../../../redux/action-constants/portal/undergraduate";
import "./index.scss";
import tipLogo from "../../../assets/tip2.png"

const getUnderGradBioDataRequest = async ({ pageProps, payload }: any) => {
  await pageProps.getUnderGradBioData(payload, true);
};

const underGradUpdateBioDataRequest = async ({
  pageProps,
  payload,
  passport,
}: any) => {
  await pageProps.underGradUpdateBioData(payload, passport);
};

const StudentBiodataInfo = ({ pageProps }: any) => {
  const [studentDob, onDobChange] = useState<any>();
  const [stateOfOrigin, setStateOfOrigin] = useState<any>();
  const [stateLgas, setStateLgas] = useState<any[]>([]);
  const [defaultLga, setDefaultLga] = useState<any>("");
  const [studentImgFile, setStudentImgFile] = useState<any>();
  const [studentPassport, setStudentPassPort] = useState<any>();
  const [imageError, setImageError] = useState<any>();
  const [isImagePending, setIsImagePending] = useState<boolean>(false);
  const [allAwards, setAllAwards] = useState<any[]>([]);
  const [countriesList, setAllCounntriesList] = useState<any[]>([]);
  let requestInfo =
    pageProps.UnderGradGetBioDataRequest?.request_data?.response;
  let updateBiorequest = pageProps.UGradUpdateBioDataRequest;

  let isUpdateUnAllowed = false;
  
  let yofE = requestInfo?.currentSession.split("/")[0];
  isUpdateUnAllowed = yofE !== requestInfo?.entryYear;
    if(yofE !== requestInfo?.entryYear){
      isUpdateUnAllowed = true
    }else{
      isUpdateUnAllowed = false
    }


  useEffect(() => {
    // console.log("requestInfo", requestInfo)
    if (requestInfo?.state) {
      let lga = getStateLgas(requestInfo?.state);

      if (lga) {
        setStateLgas(lga);
      }

      if (requestInfo?.lga) {
        setDefaultLga(requestInfo?.lga.toLowerCase());
      }
    }
    if (requestInfo?.allAwards) {
      setAllAwards(requestInfo?.allAwards);
    }
    //   if (requestInfo?.countriesList) {
    //     setAllCounntriesList(requestInfo?.countriesList);
    //   }
  }, []);

  useEffect(() => {
    if (stateOfOrigin) {
      let lga = getStateLgas(stateOfOrigin);
      setStateLgas(lga);
    }
  }, [stateOfOrigin]);

  const handleLogoUpload = (e: any) => {
    e.preventDefault();

    if (e.target.files.length > 0) {
      setStudentPassPort({});
      let reader = new FileReader();
      const img: any = document.createElement("img");
      let file = e.target.files[0];
      setStudentImgFile(file);
      let imageObj: any = {};

      reader.onload = (e: any) => {
        let fileSize = file.size / 1024;
        img.src = reader.result;

        if (file.type == "image/jpeg" || file.type == "image/jpg") {
          imageObj = { file, image: reader.result };

          setImageError("");
        } else {
          setStudentImgFile(null);
          setImageError("ONLY Jpeg format is allowed");
        }

        if (fileSize >= 30) {
          setImageError("Image size must be less than 30kb");
          setStudentImgFile(null);
        } else {
          setImageError("");
        }
      };

      reader.readAsDataURL(file);

      img.onload = (e: any) => {
        if (img.width == 150 && img.height == 150) {
          setImageError("");
          setStudentPassPort(imageObj);
          setIsImagePending(false);
        } else {
          setImageError("Image must be Exactly 150px by 150px");
        }
      };
    }
  };

  const dateReformatter = (date: any) => {
    let dateValue = requestInfo?.dateOfBirth.split("T");
    dateValue = dateValue[0].split("-");
    let yearTxt,
      dayTxt,
      monthTxt,
      formattedDateTxt: any = "";
    if (dateValue.length == 3) {
      if (dateValue[0].length == 4) {
        // check that first item is year
        yearTxt = dateValue[0];

        if (dateValue[1] <= 12) {
          dayTxt = dateValue[2];
          monthTxt = dateValue[1];
        }
        if (dateValue[1] > 12) {
          dayTxt = dateValue[1];
          monthTxt = dateValue[2];
        }
      }
      if (dateValue[2].length == 4) {
        // check that first item is year
        yearTxt = dateValue[2];

        if (dateValue[1] <= 12) {
          dayTxt = dateValue[0];
          monthTxt = dateValue[1];
        }
        if (dateValue[1] > 12) {
          dayTxt = dateValue[1];
          monthTxt = dateValue[0];
        }
      }

      dayTxt = dayTxt < 10 && dayTxt.length < 2 ? `0${dayTxt}` : dayTxt;
      monthTxt =
        monthTxt < 10 && monthTxt.length < 2 ? `0${monthTxt}` : monthTxt;

      formattedDateTxt = new Date(`${monthTxt}/${dayTxt}/${yearTxt}`);
    }

    return formattedDateTxt;
  };

  let allStates = getStates();
  const sortAlphaNum = (a: any, b: any) =>
    a?.localeCompare(b, "en", { numeric: true });
  allStates = allStates.sort(sortAlphaNum);
  allStates = allStates.filter((eachState) => eachState !== "Niger");

  let checkValidationSchema = Yup.object().shape({
    maritalStatus: Yup.string().required("Marital Status is required."),
    awardInView: Yup.string().required("Award-In-View is required."),
    modeOfEntry: Yup.string().required("Mode of Entry is required."),

    stateOfOrigin: Yup.string().required("State of origin is required."),
    lga: Yup.string().required("LGA is required."),
    dateOfBirth: Yup.string().required("Date of Birth is Required"),
    placeOfBirth: Yup.string().required("Place of birth is required."),
    phoneNumber: Yup.string()
      .matches(/^\+?[0-9]{7,20}$/, "Phone number must be 8 digits or more")
      .required("Phone number is required"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("email is required"),

    programDuration: Yup.string().required("Course Duration is required."),
    programme: Yup.string().required("Programme of Study is required."),
    presentContactAddress: Yup.string().required(
      "Present Address is required."
    ),
    permanentHomeAddress: Yup.string().required(
      "Permanent Address is required."
    ),
    nextOfKin: Yup.string().required("Next of Kin is required."),
    nextOfKinRelationship: Yup.string().required(
      "Next of Kin relatinship is required."
    ),
    nextOfKinPhoneNumber: Yup.string()
      .matches(/^\+?[0-9]{7,20}$/, "Phone number must be 8 digits or more")
      .required("Next of Kin number is required"),
    nextOfKinAddress: Yup.string().required("Next of Kin address is required."),
    sponsorType: Yup.string().required("Sponsor is required."),
    healthStatus: Yup.string().required("Health status is required."),
    bloodGroup: Yup.string().required("Blood group is required."),
  });

  let dateValue: any, dateformatted: any;
  if (requestInfo?.dateOfBirth) {
    // dateValue = requestInfo?.dateOfBirth.split("T");
    // dateValue = dateValue[0].split("-");

    // if (dateValue.length == 3) {
    //     dateformatted = `${dateValue[1].length > 1 ? dateValue[1] : `0${dateValue[1]}`}/${dateValue[2]}/${dateValue[0]}`;

    //     dateformatted = new Date(dateformatted);
    // }

    dateformatted = dateReformatter(requestInfo?.dateOfBirth);
  }

  let initialValues: any = {
    phoneNumber: requestInfo?.phoneNumber,
    email: requestInfo?.email,
    religion: requestInfo.religion ? requestInfo.religion.toLowerCase() : "",
    stateOfOrigin: requestInfo?.state ? requestInfo?.state.toLowerCase() : "",
    lga: requestInfo?.lga && defaultLga ? defaultLga : "",
    dateOfBirth: dateformatted || "",
    // dateOfBirth: requestInfo?.dateOfBirth || "",
    placeOfBirth: requestInfo?.placeOfBirth || "",
    maritalStatus: requestInfo.maritalStatus
      ? requestInfo.maritalStatus.toLowerCase()
      : "",
    // maritalStatus: requestInfo?.maritalStatus || "",
    presentContactAddress: requestInfo?.presentContactAddress || "",
    permanentHomeAddress: requestInfo?.permanentHomeAddress || "",
    nextOfKin: requestInfo?.nextOfKin || "",
    nextOfKinRelationship: requestInfo?.nextOfKinRelationship
      ? requestInfo?.nextOfKinRelationship.toLowerCase()
      : "",
    nextOfKinPhoneNumber: requestInfo?.nextOfKinPhoneNumber || "",
    nextOfKinAddress: requestInfo?.nextOfKinAddress || "",
    sponsorType: requestInfo?.sponsorType
      ? requestInfo?.sponsorType.toLowerCase()
      : "",
    sponsorAddress: requestInfo?.sponsorAddress || "",
    institutionObtained: requestInfo?.institutionObtained || "",
    previousUniversity: requestInfo?.previousUniversity || "",
    subjectOfFirstDegree: requestInfo?.subjectOfFirstDegree || "",
    healthStatus: requestInfo?.healthStatus
      ? requestInfo?.healthStatus.toLowerCase()
      : "",
    programDuration: requestInfo?.programDuration || "",
    disability: requestInfo?.disability || "None",
    bloodGroup: requestInfo?.bloodGroup || "",
    medication: requestInfo?.medication || "",
    extraActivities: requestInfo?.extraActivities || "",
    programme: requestInfo.programmeUniqueId || "",
    // programme: requestInfo.programme || "",
    awardInView: requestInfo.awardInView
      ? requestInfo.awardInView.toLowerCase()
      : "",
    highestQualification: requestInfo.highestQualification || "",
    modeOfEntry: requestInfo?.modeOfEntry || "",

    // maritalStatus: requestInfo?.maritalStatus,
  };

  let timestamp = new Date();

  // console.log("requestInfo", requestInfo)
  return (
    <div>
      {/* {requestInfo?.hasUpdatedBioData &&
                <div className="required_action_alert">
                    If you need to  <span>update your bio-data</span> kindly proceed to ICT admin office.
                </div>
            } */}

      {/* {!requestInfo?.hasUpdatedBioData && */}
      <>
        {updateBiorequest.request_status !==
          appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_SUCCESS && (
          <>
            {/* {!isUpdateUnAllowed &&
                            <div className="required_action_alert">
                                You <span>must update your bio-data</span> before you can take any action on your portal.
                            </div>
                        }
                        {isUpdateUnAllowed &&
                            <div className="required_action_alert">
                                Please note that <span>bio-data update for the session</span> is closed.
                            </div>
                        } */}
            {allAwards.length >= 1 && (
              <Formik
                initialValues={initialValues}
                validationSchema={checkValidationSchema}
                onSubmit={async (values) => {
                  let photoId = requestInfo?.photoId
                    ? requestInfo?.photoId.substring(
                        requestInfo?.photoId.lastIndexOf("/") + 1
                      )
                    : null;

                  let dateOfBirth: any = values?.dateOfBirth;

                  let day =
                    dateOfBirth.getDate() < 10
                      ? `0${dateOfBirth.getDate()}`
                      : dateOfBirth.getDate();
                  let month =
                    dateOfBirth.getMonth() + 1 < 10
                      ? `0${dateOfBirth.getMonth() + 1}`
                      : dateOfBirth.getMonth() + 1;
                  dateOfBirth = `${dateOfBirth.getFullYear()}-${month}-${day}`;

                  if ((photoId !== null || studentImgFile) && !isImagePending) {
                    setIsImagePending(false);

                    let payload: any = {
                      studentUniqueId: requestInfo?.studentUniqueId,
                      fullName: requestInfo?.fullName,
                      email: values?.email,
                      phoneNumber: values?.phoneNumber,
                      surName: requestInfo?.surName,
                      firstName: requestInfo?.firstName,
                      otherNames: requestInfo?.otherNames,
                      state:
                        values?.stateOfOrigin.toLowerCase() !== "niger"
                          ? values?.stateOfOrigin
                          : "",
                      matricNumber: requestInfo?.matricNumber,
                      level: requestInfo?.level,
                      gender: requestInfo?.gender,
                      dateOfBirth,
                      // dateOfBirth: values?.dateOfBirth,
                      photoId,
                      placeOfBirth: values.placeOfBirth,
                      maritalStatus: values?.maritalStatus,
                      religion: values.religion,
                      nationality: requestInfo?.nationality,
                      lga: values?.lga,
                      presentContactAddress: values?.presentContactAddress,
                      permanentHomeAddress: values.permanentHomeAddress,
                      nextOfKin: values.nextOfKin,
                      nextOfKinAddress: values.nextOfKinAddress,
                      nextOfKinPhoneNumber: values.nextOfKinPhoneNumber,
                      nextOfKinRelationship: values.nextOfKinRelationship,
                      sponsorType: values.sponsorType,
                      sponsorName: values.sponsorName,
                      sponsorAddress: values.sponsorAddress,
                      programType: requestInfo?.programType,
                      awardInView: values.awardInView,
                      highestQualification: values.highestQualification,
                      institutionObtained: values.institutionObtained,
                      previousUniversity: values.previousUniversity,
                      subjectOfFirstDegree: values.subjectOfFirstDegree,
                      healthStatus: values.healthStatus,
                      bloodGroup: values.bloodGroup,
                      disability: values.disability,
                      medication: values.medication,
                      extraActivities: values.extraActivities,
                      programmeUniqueId: values.programme,
                      programDuration: values.programDuration,
                      modeOfEntry: values?.modeOfEntry,
                    };

                    let passportInfo;

                    if (studentImgFile) {
                      passportInfo = new FormData();
                      passportInfo.append("File", studentImgFile);
                      passportInfo.append(
                        "StudentUniqueId",
                        requestInfo?.studentUniqueId
                      );
                    }

                    // console.log("payload", payload)

                    underGradUpdateBioDataRequest({
                      pageProps,
                      payload,
                      passport: passportInfo,
                    });
                  } else {
                    setIsImagePending(true);
                  }
                }}
              >
                {(props: FormikProps<any>) => {
                  const {
                    values,
                    touched,
                    errors,
                    // handleBlur,
                    handleChange,
                    setFieldTouched,
                    setFieldValue,
                    // isSubmitting,
                  } = props;
                  return (
                    <Form>
                      <div className="each_edit_section">
                        {isUpdateUnAllowed==false &&
                        <div className="heading-wrapper">
                            
                            <h1 className="each_edit_section_heading">
                                <span><img src={tipLogo} alt="" /></span>
                                Kindly ensure you update your bio-data before the end of this session. You will no longer be able to update your bio-data after this session.
                              {/* You are only allowed to update your email address.
                              Please ensure that your email is valid. */}
                            </h1>
                        </div>
                        }
                        <div className="edit_heading">STUDENT BIODATA</div>
                        <div className="edit_data three">
                          <div className="form-group">
                            <label className="label">Surname * </label>
                            <div className="">
                              <Field
                                value={requestInfo?.surName}
                                id="surName"
                                name="surName"
                                disabled
                                type="text"
                                onChange={() => {}}
                                placeholder=""
                                className={`input-wrap valid-input `}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label">First Name * </label>
                            <div className="">
                              <Field
                                value={requestInfo?.firstName}
                                id="firstName"
                                disabled
                                name="firstName"
                                type="text"
                                onChange={() => {}}
                                placeholder=""
                                className={`input-wrap valid-input `}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label">Middle Name </label>
                            <div className="">
                              <Field
                                value={requestInfo?.otherNames}
                                id="otherNames"
                                disabled
                                name="otherNames"
                                type="text"
                                onChange={() => {}}
                                placeholder=""
                                className={`input-wrap valid-input`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="edit_data three">
                          <div className="form-group">
                            <label className="label">Gender * </label>
                            <div className="">
                              <Field
                                value={requestInfo?.gender}
                                id="gender"
                                name="gender"
                                disabled
                                type="text"
                                onChange={() => {}}
                                placeholder=""
                                className={`input-wrap valid-input `}
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="label">Marital Status * </label>
                            <div
                              className={
                                errors.maritalStatus && touched.maritalStatus
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="maritalStatus"
                                id="maritalStatus"
                                value={values?.maritalStatus}
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                disabled={isUpdateUnAllowed}
                                className={`input-wrap ${
                                  errors.maritalStatus && touched.maritalStatus
                                    ? "is-invalid-input "
                                    : !errors.maritalStatus &&
                                      values.maritalStatus !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option value="">Select </option>
                                <option value="single">Single</option>
                                <option value="married">Married</option>
                              </select>
                            </div>
                            <ErrorMessage
                              name="maritalStatus"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">Religion * </label>
                            <div
                              className={
                                errors.religion && touched.religion
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="religion"
                                id="religion"
                                value={values?.religion}
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                disabled={isUpdateUnAllowed}
                                className={`input-wrap ${
                                  errors.religion && touched.religion
                                    ? "is-invalid-input "
                                    : !errors.religion && values.religion !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option value="">Select </option>
                                <option value="christian">Christian</option>
                                <option value="islam">Islam</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="edit_data three">
                          <div className="form-group">
                            <label className="label">State of Origin * </label>
                            <div className="">
                              {/* {requestInfo?.state && requestInfo?.state.toLowerCase() !== "others" && requestInfo?.state !== "" && */}
                              {/* {((requestInfo?.state && requestInfo?.state.toLowerCase() == "niger") || (requestInfo?.level && requestInfo?.level == 100)) && */}
                              {requestInfo?.state &&
                                requestInfo?.state.toLowerCase() == "niger" && (
                                  <Field
                                    value={requestInfo?.state}
                                    id="gender"
                                    name="gender"
                                    disabled
                                    type="text"
                                    onChange={() => {}}
                                    placeholder=""
                                    className={`input-wrap valid-input `}
                                  />
                                )}
                            </div>
                            {/* {((requestInfo?.state.toLowerCase() !== "niger" || requestInfo?.state == "") && requestInfo?.level && requestInfo?.level > 100) && */}
                            {(requestInfo?.state.toLowerCase() !== "niger" ||
                              requestInfo?.state == "") && (
                              <>
                                <div
                                  className={
                                    errors.stateOfOrigin &&
                                    touched.stateOfOrigin
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <select
                                    name="stateOfOrigin"
                                    id="stateOfOrigin"
                                    value={values?.stateOfOrigin}
                                    // disabled={requestInfo?.state && requestInfo?.state.toLowerCase() !== "others" && requestInfo?.state.toLowerCase() !== "other" && requestInfo?.state !== ""}
                                    disabled={isUpdateUnAllowed}
                                    onChange={(e: any) => {
                                      if (e.target.value) {
                                        setFieldTouched(
                                          "stateOfOrigin",
                                          true,
                                          true
                                        );
                                        setFieldValue(
                                          "stateOfOrigin",
                                          e.target.value
                                        );
                                        setFieldValue("lga", "");
                                        setFieldTouched("lga", true, true);
                                        setStateOfOrigin(e.target.value);
                                      }
                                    }}
                                    className={`input-wrap ${
                                      errors.stateOfOrigin &&
                                      touched.stateOfOrigin
                                        ? "is-invalid-input "
                                        : !errors.stateOfOrigin &&
                                          values.stateOfOrigin !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  >
                                    <option value="">Select </option>
                                    {allStates.map(
                                      (eachState: any, index: any) => {
                                        return (
                                          <option
                                            value={eachState.toLowerCase()}
                                            key={index}
                                          >
                                            {eachState}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                                <ErrorMessage
                                  name="stateOfOrigin"
                                  className="form-input-error"
                                  component="div"
                                />
                              </>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="label">Local Govt. Area * </label>
                            {/* {requestInfo?.level && requestInfo?.level > 100 && */}
                            <div>
                              <div
                                className={
                                  errors.lga && touched.lga
                                    ? "invalid-input-wrap"
                                    : ""
                                }
                              >
                                <select
                                  name="lga"
                                  id="lga"
                                  disabled={
                                    !values?.stateOfOrigin || isUpdateUnAllowed
                                  }
                                  value={values?.lga || defaultLga}
                                  // value={values?.lga}
                                  onChange={
                                    !isUpdateUnAllowed ? handleChange : () => {}
                                  }
                                  className={`input-wrap ${
                                    errors.lga && touched.lga
                                      ? "is-invalid-input "
                                      : !errors.lga && values.lga !== ""
                                      ? "valid-input"
                                      : ""
                                  }`}
                                >
                                  <option value="">Select </option>
                                  {stateLgas.map((eachlga: any, index: any) => {
                                    return (
                                      <option
                                        value={eachlga.toLowerCase()}
                                        key={index}
                                      >
                                        {eachlga}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <ErrorMessage
                                name="lga"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                            {/* } */}
                            {/* {((requestInfo?.level && requestInfo?.level == 100)) &&
                                                            <Field
                                                                value={defaultLga}
                                                                id="gender"
                                                                name="gender"
                                                                disabled
                                                                type="text"
                                                                onChange={() => { }}
                                                                placeholder=""
                                                                className={`input-wrap valid-input `}
                                                            />
                                                        } */}
                          </div>
                          <div className="form-group">
                            <label className="label">Date of Birth * </label>
                            <div
                              className={
                                errors.dateOfBirth && touched.dateOfBirth
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <DatePicker
                                // selected={studentDob}
                                selected={values?.dateOfBirth || studentDob}
                                disabled={isUpdateUnAllowed}
                                onChange={(date: any) => {
                                  onDobChange(date);
                                  setFieldTouched("dateOfBirth", true);

                                  setFieldValue("dateOfBirth", date);
                                }}
                                // minDate={new Date()}
                                name=""
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat="d MMMM, yyyy"
                                maxDate={new Date()}
                                placeholderText="Select Date of Birth"
                              />
                            </div>
                            <ErrorMessage
                              name="dateOfBirth"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="edit_data three">
                          <div className="form-group">
                            <label className="label">Country of Origin *</label>
                            <div className="">
                              <Field
                                value={requestInfo?.nationality}
                                id="nationality"
                                name="nationality"
                                disabled
                                type="text"
                                onChange={() => {}}
                                placeholder=""
                                className={`input-wrap valid-input `}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label">Place of Birth * </label>
                            <div
                              className={
                                errors.placeOfBirth && touched.placeOfBirth
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={values.placeOfBirth}
                                id="placeOfBirth"
                                name="placeOfBirth"
                                disabled={isUpdateUnAllowed}
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.placeOfBirth && touched.placeOfBirth
                                    ? "is-invalid-input "
                                    : !errors.placeOfBirth &&
                                      values.placeOfBirth !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                            </div>
                            <ErrorMessage
                              name="placeOfBirth"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="each_edit_section">
                        <div className="edit_heading">PASSPORT PHOTOGRAPH</div>
                        {/* <div className="section_help">
                                                    Upload picture (the uploaded picture will not be save until after your have click on the save or update button on the bottom of this page/form)
                                                </div> */}
                        <div className="passport_upload">
                          {studentPassport?.image && (
                            <div className="photo_wrap">
                              <img
                                src={
                                  studentPassport?.image || requestInfo.photoId
                                }
                                alt=""
                              />
                            </div>
                          )}
                          {requestInfo?.photoId && !studentPassport?.image && (
                            <div className="photo_wrap">
                              <img
                                src={`${
                                  requestInfo.photoId
                                }?v=${timestamp.getTime()}`}
                                alt=""
                              />
                            </div>
                          )}
                          {/* {requestInfo?.level && requestInfo?.level > 100 && */}
                          {!isUpdateUnAllowed && (
                            <div className="upload_ctas">
                              <div className="file_input">
                                <input
                                  disabled={isUpdateUnAllowed}
                                  accept="image/jpg, image/jpeg"
                                  onChange={(e: any) =>
                                    isUpdateUnAllowed
                                      ? handleLogoUpload(e)
                                      : () => {}
                                  }
                                  type="file"
                                  name=""
                                  id="passportImg"
                                />
                                <label htmlFor="passportImg" className="">
                                  {studentPassport?.image ||
                                  requestInfo?.photoId
                                    ? "Change passport"
                                    : "Choose passport"}
                                </label>
                              </div>

                              {/* <button className="btn shaded" type="button">Upload</button> */}
                            </div>
                          )}
                          {/* } */}
                          {imageError && (
                            <div className="img_upload_error">
                              {" "}
                              {imageError}
                            </div>
                          )}
                        </div>
                        {/* <div className="upload_help">
                                    Click on the choose file button to select your photo and then click on the upload button to save it temporary before saving your information. The uploaded picture MUST conform with the following:
                                </div> */}
                        {/* {requestInfo?.level && requestInfo?.level > 100 && */}
                        {!isUpdateUnAllowed && (
                          <>
                            <div className="uploads_checklist">
                              <ul>
                                <li>Jpeg format ONLY</li>
                                <li>Less than 30KB</li>
                                <li>Exactly 150px by 150px</li>
                                <li>Recent Photograph of good quality</li>
                                <li>Plain white background</li>
                              </ul>
                            </div>
                            <div className="upload_help">
                              Note that the passport photograph you supply here
                              will be used for ID Card and any other business
                              the university might need your photograph for.
                            </div>
                          </>
                        )}
                        {/* } */}
                      </div>
                      <div className="each_edit_section">
                        <div className="edit_heading">CONTACT INFORMATION</div>
                        <div className="edit_data two">
                          <div className="form-group">
                            <label className="label">Student Phone No </label>
                            <div
                              className={
                                errors.phoneNumber && touched.phoneNumber
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={values?.phoneNumber}
                                id="phoneNumber"
                                name="phoneNumber"
                                disabled={isUpdateUnAllowed}
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.phoneNumber && touched.phoneNumber
                                    ? "is-invalid-input "
                                    : !errors.phoneNumber &&
                                      values.phoneNumber !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="phoneNumber"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label">Student Email </label>
                            <div
                              className={
                                errors.email && touched.email
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={values?.email}
                                id="email"
                                name="email"
                                type="text"
                                disabled
                                onChange={()=>{}}
                                placeholder=""
                                className={`input-wrap ${
                                  errors.email && touched.email
                                    ? "is-invalid-input "
                                    : !errors.email && values.email !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="email"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="edit_data ">
                          <div className="form-group">
                            <label className="label">
                              Present Contact Address *{" "}
                            </label>
                            <div
                              className={
                                errors.presentContactAddress &&
                                touched.presentContactAddress
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={values?.presentContactAddress}
                                id="presentContactAddress"
                                name="presentContactAddress"
                                disabled={isUpdateUnAllowed}
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.presentContactAddress &&
                                  touched.presentContactAddress
                                    ? "is-invalid-input "
                                    : !errors.presentContactAddress &&
                                      values.presentContactAddress !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="presentContactAddress"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="edit_data ">
                          <div className="form-group">
                            <label className="label">Permanent Address </label>
                            <div
                              className={
                                errors.permanentHomeAddress &&
                                touched.permanentHomeAddress
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={values?.permanentHomeAddress}
                                id="permanentHomeAddress"
                                name="permanentHomeAddress"
                                disabled={isUpdateUnAllowed}
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.permanentHomeAddress &&
                                  touched.permanentHomeAddress
                                    ? "is-invalid-input "
                                    : !errors.permanentHomeAddress &&
                                      values.permanentHomeAddress !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="permanentHomeAddress"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="edit_data three">
                          <div className="form-group">
                            <label className="label">Next Of Kin Name</label>
                            <div
                              className={
                                errors.nextOfKin && touched.nextOfKin
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={values.nextOfKin}
                                id="nextOfKin"
                                name="nextOfKin"
                                disabled={isUpdateUnAllowed}
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.nextOfKin && touched.nextOfKin
                                    ? "is-invalid-input "
                                    : !errors.nextOfKin &&
                                      values.nextOfKin !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="nextOfKin"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label">Relationship</label>
                            <div
                              className={
                                errors.nextOfKinRelationship &&
                                touched.nextOfKinRelationship
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="nextOfKinRelationship"
                                id="nextOfKinRelationship"
                                value={values?.nextOfKinRelationship}
                                disabled={isUpdateUnAllowed}
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                className={`input-wrap ${
                                  errors.nextOfKinRelationship &&
                                  touched.nextOfKinRelationship
                                    ? "is-invalid-input "
                                    : !errors.nextOfKinRelationship &&
                                      values.nextOfKinRelationship !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option value="">Select </option>
                                <option value="parent">Parent</option>
                                <option value="spouse">Spouse</option>
                                <option value="child">Child</option>
                                <option value="sibling">Sibling</option>
                                <option value="grandparent">Grandparent</option>
                                <option value="grandchild">Grandchild</option>
                                <option value="grandchild">Grandchild</option>
                                <option value="aunt">Aunt</option>
                                <option value="uncle">Uncle</option>
                                <option value="niece">Niece</option>
                                <option value="nephew">Nephew</option>
                                <option value="cousin">Cousin</option>
                                <option value="other">Other</option>
                              </select>
                            </div>
                            <ErrorMessage
                              name="nextOfKinRelationship"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Next Of Kin Phone No{" "}
                            </label>
                            <div
                              className={
                                errors.nextOfKinPhoneNumber &&
                                touched.nextOfKinPhoneNumber
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={values?.nextOfKinPhoneNumber}
                                id="nextOfKinPhoneNumber"
                                name="nextOfKinPhoneNumber"
                                disabled={isUpdateUnAllowed}
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.nextOfKinPhoneNumber &&
                                  touched.nextOfKinPhoneNumber
                                    ? "is-invalid-input "
                                    : !errors.nextOfKinPhoneNumber &&
                                      values.nextOfKinPhoneNumber !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="nextOfKinPhoneNumber"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="edit_data ">
                          <div className="form-group">
                            <label className="label">
                              Next Of Kin Address{" "}
                            </label>
                            <div
                              className={
                                errors.nextOfKinAddress &&
                                touched.nextOfKinAddress
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={requestInfo?.nextOfKinAddress}
                                id="nextOfKinAddress"
                                name="nextOfKinAddress"
                                disabled={isUpdateUnAllowed}
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.nextOfKinAddress &&
                                  touched.nextOfKinAddress
                                    ? "is-invalid-input "
                                    : !errors.nextOfKinAddress &&
                                      values.nextOfKinAddress !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="nextOfKinAddress"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="edit_data two">
                          <div className="form-group">
                            <label className="label">Sponsorship Type * </label>
                            <div
                              className={
                                errors.sponsorType && touched.sponsorType
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="sponsorType"
                                id="sponsorType"
                                value={values?.sponsorType}
                                disabled={isUpdateUnAllowed}
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                className={`input-wrap ${
                                  errors.sponsorType && touched.sponsorType
                                    ? "is-invalid-input "
                                    : !errors.sponsorType &&
                                      values.sponsorType !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option value="">Select </option>
                                <option value="self">Self</option>
                                <option value="sponsored">Sponsored</option>
                              </select>
                            </div>
                            <ErrorMessage
                              name="sponsorType"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">Sponsor Name </label>
                            <div
                              className={
                                errors.sponsorName && touched.sponsorName
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={requestInfo?.sponsorName}
                                id="sponsorName"
                                name="sponsorName"
                                disabled={isUpdateUnAllowed}
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.sponsorName && touched.sponsorName
                                    ? "is-invalid-input "
                                    : !errors.sponsorName &&
                                      values.sponsorName !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                            </div>
                            <ErrorMessage
                              name="sponsorName"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="edit_data ">
                          <div className="form-group">
                            <label className="label">Sponsor Address </label>
                            <div
                              className={
                                errors.sponsorAddress && touched.sponsorAddress
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={requestInfo?.sponsorAddress}
                                id="sponsorAddress"
                                disabled={isUpdateUnAllowed}
                                name="sponsorAddress"
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.sponsorAddress &&
                                  touched.sponsorAddress
                                    ? "is-invalid-input "
                                    : !errors.sponsorAddress &&
                                      values.sponsorAddress !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="sponsorAddress"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="each_edit_section">
                        <div className="edit_heading">ACADEMIC INFORMATION</div>
                        <div className="edit_data three">
                          <div className="form-group">
                            <label className="label">Program Type * </label>
                            <div className="">
                              <Field
                                value={requestInfo?.programType}
                                id="programType"
                                name="programType"
                                disabled
                                type="text"
                                onChange={() => {}}
                                placeholder=""
                                className={`input-wrap valid-input `}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label">Study Mode * </label>
                            <div className="">
                              <Field
                                value={requestInfo?.studyMode}
                                id="studyMode"
                                disabled
                                name="studyMode"
                                type="text"
                                onChange={() => {}}
                                placeholder=""
                                className={`input-wrap valid-input `}
                              />
                            </div>
                          </div>
                          {/* <div className="form-group">
                                                        <label className="label">Entry Mode *  </label>
                                                        <div className="">
                                                            <Field
                                                                value={requestInfo?.modeOfEntry}
                                                                id="modeOfEntry"
                                                                // disabled
                                                                name="modeOfEntry"
                                                                type="text"
                                                                onChange={() => { }}
                                                                placeholder=""
                                                                className={`input-wrap valid-input`}
                                                            />
                                                        </div>
                                                    </div> */}
                          <div className="form-group">
                            <label className="label">Entry Mode * </label>
                            <div
                              className={
                                errors.modeOfEntry && touched.modeOfEntry
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="modeOfEntry"
                                id="modeOfEntry"
                                disabled={isUpdateUnAllowed}
                                value={values?.modeOfEntry}
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                className={`input-wrap ${
                                  errors.modeOfEntry && touched.modeOfEntry
                                    ? "is-invalid-input "
                                    : !errors.modeOfEntry &&
                                      values.modeOfEntry !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option value="">Select </option>
                                <option value="UTME">UTME </option>
                                <option value="DE">DE </option>
                              </select>
                            </div>
                            <ErrorMessage
                              name="modeOfEntry"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="edit_data three">
                          <div className="form-group">
                            <label className="label">Faculty *</label>
                            <div className="">
                              <Field
                                value={requestInfo?.faculty}
                                id="faculty"
                                name="faculty"
                                disabled
                                type="text"
                                onChange={() => {}}
                                placeholder=""
                                className={`input-wrap valid-input `}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label">Department *</label>
                            <div className="">
                              <Field
                                value={requestInfo?.department}
                                id="department"
                                name="department"
                                disabled
                                type="text"
                                onChange={() => {}}
                                placeholder=""
                                className={`input-wrap valid-input `}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label">Programme</label>
                            {!requestInfo.allProgrammes && (
                              <div className="">
                                <Field
                                  value={requestInfo?.programme}
                                  id="programme"
                                  name="programme"
                                  disabled
                                  type="text"
                                  onChange={() => {}}
                                  placeholder=""
                                  className={`input-wrap valid-input `}
                                />
                              </div>
                            )}
                            {requestInfo.allProgrammes && (
                              <>
                                <div
                                  className={
                                    errors.programme && touched.programme
                                      ? "invalid-input-wrap"
                                      : ""
                                  }
                                >
                                  <select
                                    name="programme"
                                    id="programme"
                                    value={values?.programme}
                                    disabled={isUpdateUnAllowed}
                                    onChange={
                                      !isUpdateUnAllowed
                                        ? handleChange
                                        : () => {}
                                    }
                                    className={`input-wrap ${
                                      errors.programme && touched.programme
                                        ? "is-invalid-input "
                                        : !errors.programme &&
                                          values.programme !== ""
                                        ? "valid-input"
                                        : ""
                                    }`}
                                  >
                                    <option value="">Select </option>
                                    {requestInfo.allProgrammes &&
                                      requestInfo.allProgrammes.map(
                                        (eachProgram: any, index: any) => {
                                          if (
                                            eachProgram?.programmeName !== "-"
                                          ) {
                                            return (
                                              <option
                                                key={
                                                  eachProgram?.programmeUniqueId
                                                }
                                                value={
                                                  eachProgram?.programmeUniqueId
                                                }
                                              >
                                                {eachProgram?.programmeName}{" "}
                                              </option>
                                            );
                                          }
                                          return "";
                                        }
                                      )}
                                  </select>
                                </div>
                                <ErrorMessage
                                  name="programme"
                                  className="form-input-error"
                                  component="div"
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="edit_data three">
                          {/* <div className="form-group">
                                                        <label className="label">Award in View * </label>
                                                        <div className="">
                                                            <Field
                                                                value={requestInfo?.awardInView}
                                                                id="awardInView"
                                                                name="awardInView"
                                                                disabled
                                                                type="text"
                                                                onChange={() => { }}
                                                                placeholder=""
                                                                className={`input-wrap valid-input `}
                                                            />
                                                        </div>

                                                    </div> */}
                          <div className="form-group">
                            <label className="label">Award in View * </label>
                            <div
                              className={
                                errors.awardInView && touched.awardInView
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="awardInView"
                                id="awardInView"
                                value={values?.awardInView}
                                disabled={isUpdateUnAllowed}
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                className={`input-wrap ${
                                  errors.awardInView && touched.awardInView
                                    ? "is-invalid-input "
                                    : !errors.awardInView &&
                                      values.awardInView !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option value="">Select </option>
                                {requestInfo?.allAwards &&
                                  requestInfo?.allAwards.map(
                                    (eachItem: any, index: any) => {
                                      return (
                                        <option
                                          key={index}
                                          value={eachItem?.name.toLowerCase()}
                                        >
                                          {eachItem?.name}
                                        </option>
                                        // <option key={index} value={eachItem?.uniqueId}>{eachItem?.name}</option>
                                      );
                                    }
                                  )}
                                {/* <option value="Bachelors">Bachelors</option>
                                                                <option value="Master">Master</option>
                                                                <option value="Phd">Phd</option> */}
                              </select>
                            </div>
                            <ErrorMessage
                              name="awardInView"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">Course Duration * </label>
                            <div
                              className={
                                errors.programDuration &&
                                touched.programDuration
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="programDuration"
                                id="programDuration"
                                value={values?.programDuration}
                                disabled={isUpdateUnAllowed}
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                className={`input-wrap ${
                                  errors.programDuration &&
                                  touched.programDuration
                                    ? "is-invalid-input "
                                    : !errors.programDuration &&
                                      values.programDuration !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option value="">Select </option>
                                <option value="1">One year </option>
                                <option value="2">Two years </option>
                                <option value="3">Three years </option>
                                <option value="4">Four years </option>
                                <option value="5">Five years </option>
                                <option value="6">Six years </option>
                                <option value="7">Seven years </option>
                              </select>
                            </div>
                            <ErrorMessage
                              name="programDuration"
                              className="form-input-error"
                              component="div"
                            />
                            {/* <div className="">
                                                            <Field
                                                                value={requestInfo?.programDuration}
                                                                id="programDuration"
                                                                name="programDuration"
                                                                disabled
                                                                type="text"
                                                                onChange={() => { }}
                                                                placeholder=""
                                                                className={`input-wrap valid-input `}
                                                            />
                                                        </div> */}
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Entry Year (e.g 2013) *{" "}
                            </label>
                            <div className="">
                              <Field
                                value={requestInfo?.entryYear}
                                id="entryYear"
                                name="entryYear"
                                disabled
                                type="text"
                                onChange={() => {}}
                                placeholder=""
                                className={`input-wrap valid-input `}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="edit_data ">
                          <div className="form-group">
                            <label className="label">
                              Extra Curricular Activities
                            </label>
                            <div
                              className={
                                errors.extraActivities &&
                                touched.extraActivities
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={values?.extraActivities}
                                id="extraActivities"
                                name="extraActivities"
                                disabled={isUpdateUnAllowed}
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.extraActivities &&
                                  touched.extraActivities
                                    ? "is-invalid-input "
                                    : !errors.extraActivities &&
                                      values.extraActivities !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="extraActivities"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="each_edit_section">
                        <div className="edit_heading">
                          PREVIOUS ACADEMIC HISTORY
                        </div>
                        <div className="edit_data two">
                          <div className="form-group">
                            <label className="label">
                              Highest Qualification *{" "}
                            </label>
                            <div
                              className={
                                errors.highestQualification &&
                                touched.highestQualification
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="highestQualification"
                                id="highestQualification"
                                value={values.highestQualification}
                                disabled={isUpdateUnAllowed}
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                className={`input-wrap ${
                                  errors.highestQualification &&
                                  touched.highestQualification
                                    ? "is-invalid-input "
                                    : !errors.highestQualification &&
                                      values.highestQualification !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option value="">Select </option>
                                {requestInfo?.allQualification &&
                                  requestInfo?.allQualification.map(
                                    (eachItem: any, index: any) => {
                                      return (
                                        <option
                                          key={index}
                                          value={eachItem?.name}
                                        >
                                          {eachItem?.name}
                                        </option>
                                      );
                                    }
                                  )}
                                {/* <option value="Bachelors">Bachelors</option>
                                                                <option value="Master">Master</option>
                                                                <option value="Phd">Phd</option> */}
                              </select>
                            </div>
                            <ErrorMessage
                              name="highestQualification"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Institution Obtained & Date{" "}
                            </label>
                            <div
                              className={
                                errors.institutionObtained &&
                                touched.institutionObtained
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={values?.institutionObtained}
                                // value={requestInfo?.institutionObtained}
                                id="institutionObtained"
                                name="institutionObtained"
                                disabled={isUpdateUnAllowed}
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.institutionObtained &&
                                  touched.institutionObtained
                                    ? "is-invalid-input "
                                    : !errors.institutionObtained &&
                                      values.institutionObtained !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="institutionObtained"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="edit_data two">
                          <div className="form-group">
                            <label className="label">Previous University</label>
                            <div
                              className={
                                errors.previousUniversity &&
                                touched.previousUniversity
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={values?.previousUniversity}
                                // value={requestInfo?.previousUniversity}
                                id="previousUniversity"
                                name="previousUniversity"
                                disabled={isUpdateUnAllowed}
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.previousUniversity &&
                                  touched.previousUniversity
                                    ? "is-invalid-input "
                                    : !errors.previousUniversity &&
                                      values.previousUniversity !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="previousUniversity"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label">First Degree Course</label>
                            <div
                              className={
                                errors.subjectOfFirstDegree &&
                                touched.subjectOfFirstDegree
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={values?.subjectOfFirstDegree}
                                // value={requestInfo?.subjectOfFirstDegree}
                                id="subjectOfFirstDegree"
                                name="subjectOfFirstDegree"
                                disabled={isUpdateUnAllowed}
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.subjectOfFirstDegree &&
                                  touched.subjectOfFirstDegree
                                    ? "is-invalid-input "
                                    : !errors.subjectOfFirstDegree &&
                                      values.subjectOfFirstDegree !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="subjectOfFirstDegree"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="each_edit_section">
                        <div className="edit_heading">HEALTH INFORMATION</div>
                        <div className="edit_data three">
                          <div className="form-group">
                            <label className="label">Status *</label>
                            <div
                              className={
                                errors.healthStatus && touched.healthStatus
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="healthStatus"
                                id="healthStatus"
                                value={values?.healthStatus}
                                disabled={isUpdateUnAllowed}
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                className={`input-wrap ${
                                  errors.healthStatus && touched.healthStatus
                                    ? "is-invalid-input "
                                    : !errors.healthStatus &&
                                      values.healthStatus !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option value="">Select </option>
                                <option value="healthy">Healthy</option>
                                <option value="under observation">
                                  Under observation
                                </option>
                                <option value="recovering">Recovering</option>
                                <option value="chronic condition">
                                  Chronic condition
                                </option>
                                <option value="acute illness">
                                  Acute illness
                                </option>
                                <option value="in remission">
                                  In remission
                                </option>
                                <option value="hospitalized">
                                  Hospitalized
                                </option>
                                <option value="critical condition">
                                  Critical condition
                                </option>
                              </select>
                            </div>
                            <ErrorMessage
                              name="healthStatus"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">Disability * </label>
                            <div
                              className={
                                errors.disability && touched.disability
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={values?.disability}
                                disabled={isUpdateUnAllowed}
                                id="disability"
                                name="disability"
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.disability && touched.disability
                                    ? "is-invalid-input "
                                    : !errors.disability &&
                                      values.disability !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="disability"
                                className="form-input-error"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label">Blood Group * </label>
                            <div
                              className={
                                errors.bloodGroup && touched.bloodGroup
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <select
                                name="bloodGroup"
                                id="bloodGroup"
                                value={values?.bloodGroup}
                                disabled={isUpdateUnAllowed}
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                className={`input-wrap ${
                                  errors.bloodGroup && touched.bloodGroup
                                    ? "is-invalid-input "
                                    : !errors.bloodGroup &&
                                      values.bloodGroup !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              >
                                <option value="">Select </option>
                                <option value="A+"> A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                              </select>
                            </div>
                            <ErrorMessage
                              name="bloodGroup"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="edit_data">
                          <div className="form-group">
                            <label className="label">Medication</label>
                            <div
                              className={
                                errors.medication && touched.medication
                                  ? "invalid-input-wrap"
                                  : ""
                              }
                            >
                              <Field
                                value={values?.medication}
                                disabled={isUpdateUnAllowed}
                                id="medication"
                                name="medication"
                                as="textarea"
                                type="text"
                                onChange={
                                  !isUpdateUnAllowed ? handleChange : () => {}
                                }
                                placeholder=""
                                className={`input-wrap ${
                                  errors.medication && touched.medication
                                    ? "is-invalid-input "
                                    : !errors.medication &&
                                      values.medication !== ""
                                    ? "valid-input"
                                    : ""
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form_cta">
                        <Link
                          className="btn back_cta"
                          to={
                            updateBiorequest?.is_request_processing
                              ? "#"
                              : "/undergraduate/personal-data"
                          }
                        >
                          Back
                        </Link>
                        <button
                          className="btn shaded"
                          type="submit"
                          disabled={updateBiorequest?.is_request_processing}
                          onClick={() => {}}
                        >
                          {updateBiorequest?.is_request_processing
                            ? "Updating Bio Data..."
                            : "Update Bio Data"}{" "}
                        </button>
                      </div>
                      {isImagePending && (
                        <div className="justify-center">
                          <AlertMsg
                            type="error"
                            message="Please upload your passport"
                          />
                        </div>
                      )}

                      {Object.keys(errors).length >= 1 && (
                        <div className="justify-center">
                          <AlertMsg
                            type="error"
                            message={Object.values(errors).map(
                              (msg: any, index: any) => (
                                <span key={index}>
                                  {msg}
                                  <br />
                                </span>
                              )
                            )}
                          />
                        </div>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            )}
          </>
        )}
        {updateBiorequest.request_status ===
          appConstantsUGradOPortal.UPDATE_STUDENT_BIO_DATA_SUCCESS && (
          <div className="update_success">
            <div className="success-message-wrap">
              <div className="success-animation">
                <svg
                  className="success_icon_tick"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
              <div className="success-message-txt">
                {" "}
                You have succesfully updated your biodata{" "}
              </div>
              <div className="product-cta">
                <Link
                  className="btn back_cta"
                  to="/undergraduate/personal-data"
                >
                  See updated Bio Data
                </Link>
              </div>
            </div>
          </div>
        )}

        {updateBiorequest?.request_status ===
          appConstantsUGradOPortal?.UPDATE_STUDENT_BIO_DATA_FAILURE && (
          <div className="justify-center">
            <AlertMsg
              type="error"
              message={updateBiorequest?.request_data.error}
            />
          </div>
        )}
      </>
      {/* } */}
    </div>
  );
};

const StudentBiodataDetails = ({ pageProps }: any) => {
  let authInfo = pageProps.uGradLoginRequest.request_data;
  let requestInfo = pageProps.UnderGradGetBioDataRequest;

  useEffect(() => {
    let payload = {
      matricNumber: authInfo?.matricNumber,
    };

    underGradUpdateBioDataRequest({
      pageProps,
      payload: "CLEAR",
    });
    getUnderGradBioDataRequest({
      pageProps,
      payload,
    });
  }, []);

  return (
    <div className="biodata_details edit_details">
      {requestInfo?.request_status ===
        appConstantsUGradOPortal?.GET_STUDENT_BIO_DATA_PENDING && (
        <>
          <LoadingItem />
        </>
      )}

      {requestInfo?.request_status ===
        appConstantsUGradOPortal?.GET_STUDENT_BIO_DATA_SUCCESS && (
        <StudentBiodataInfo pageProps={pageProps} />
      )}

      {requestInfo?.request_status ===
        appConstantsUGradOPortal?.GET_STUDENT_BIO_DATA_FAILURE && (
        <div className="justify-center">
          <AlertMsg type="error" message={requestInfo?.request_data.error} />
        </div>
      )}
    </div>
  );
};

const StudentBiodata = ({ pageProps }: any) => {
  return (
    <div className="biodata_info">
      {/* <div className="heading_wrap">My Personal Bio Data Update</div> */}
      <StudentBiodataDetails pageProps={pageProps} />
    </div>
  );
};

const StudentBiodataEditWrap = (pageProps: any) => {
  return (
    <UndergraduatePortal
      title="My Personal Bio Data Update"
      childComponent={<StudentBiodata pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  getUnderGradBioData: uGradPortal.UnderGradGetBioData,
  underGradUpdateBioData: uGradPortal.UnderGradUpdateBioData,
};
const mapStateToProps = (state: any) => ({
  UnderGradGetBioDataRequest: state.allUGradPortalReducers.UnderGradGetBioData,
  UGradUpdateBioDataRequest:
    state.allUGradPortalReducers.UnderGradUpdateBioData,
  uGradLoginRequest: state.allOnboardingReducers.uGradLogin,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentBiodataEditWrap);
