import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CheckCurrentStatusWrap from "./components/check-status"
import AdmissionOfferedWrap from "./components/admission-offered"
import PrintAcceptanceInvoiceWrap from "./components/print-acceptance"
import PrintSchoolFeeInvoiceWrap from "./components/print-fees"
import PrintAdmissionLetterWrap from "./components/print-admission-letter"
import "./index.scss";

import { uGradAdmissions } from "../../../../redux/action/admissions/undergraduate"
import { appConstantsUGradAdmisssions } from "../../../../redux/action-constants/admissions/undergraduate"

const getAdmissionStatus = async ({ pageProps, payload }: any) => {

    await pageProps.getMyAdmissionStatus(payload);
}


const CheckStatusLanding = (pageProps: any) => {

    const [showLanding, setShowLanding] = useState<boolean>(true);
    const [showAdmissionCongrats, setShowAdmissionCongrats] = useState<boolean>(false);
    const [showAcceptance, setShowAcceptance] = useState<boolean>(false);
    const [showSchoolFeeInvoice, setShowSchoolFeeInvoice] = useState<boolean>(false);
    const [showDownloadAdmissionLetter, setShowDownloadAdmissionLetter] = useState<boolean>(false);

    let checkMyStatus = pageProps?.uGradCheckMyStatusRequest
    useEffect(() => {


        getAdmissionStatus({
            pageProps,
            payload: "CLEAR"
        })


    }, [])

    useEffect(() => {

        if (checkMyStatus?.request_status === appConstantsUGradAdmisssions?.CHECK_ADMISSION_PROCESS_STATUS_SUCCESS
        ) {
            setShowLanding(false)

            // if (checkMyStatus?.request_data?.response?.hasGeneratedAcceptanceFeeInvoice === false && checkMyStatus?.request_data?.response?.isAdmitted) {
            if (checkMyStatus?.request_data?.response?.hasGeneratedAcceptanceFeeInvoice === false) {
                setShowAdmissionCongrats(true)
                setShowAcceptance(false)
            }
            // if (checkMyStatus?.request_data?.response?.hasGeneratedAcceptanceFeeInvoice === true && checkMyStatus?.request_data?.response?.hasPaidAcceptanceFee === false && checkMyStatus?.request_data?.response?.isAdmitted) {
            if (checkMyStatus?.request_data?.response?.hasGeneratedAcceptanceFeeInvoice === true && checkMyStatus?.request_data?.response?.hasPaidAcceptanceFee === false) {
                setShowAcceptance(true)
                setShowAdmissionCongrats(false)
            }

            // if (checkMyStatus?.request_data?.response?.hasGeneratedAcceptanceFeeInvoice === true &&
            //     checkMyStatus?.request_data?.response?.hasPaidAcceptanceFee === false) {
            //     setShowAcceptance(true)
            // }

            if (checkMyStatus?.request_data?.response?.hasPaidAcceptanceFee === true && checkMyStatus?.request_data?.response?.hasPaidSchoolFee === false) {
                // if (checkMyStatus?.request_data?.response?.programme.toUpperCase() == "MEDICINE AND SURGERY") {
                //     setShowAcceptance(true)
                //     setShowAdmissionCongrats(false)
                // } else {
                    setShowAcceptance(false)
                    setShowSchoolFeeInvoice(true)
                // }
            }

            if (checkMyStatus?.request_data?.response?.hasPaidAcceptanceFee === true && checkMyStatus?.request_data?.response?.hasPaidSchoolFee === true) {
                setShowSchoolFeeInvoice(false)
                setShowAcceptance(false)
                setShowDownloadAdmissionLetter(true)
            }

        }






    }, [checkMyStatus?.request_status])
    return (
        <div className="status_landing_Wrap">
            {(checkMyStatus?.request_status !== appConstantsUGradAdmisssions?.CHECK_ADMISSION_PROCESS_STATUS_SUCCESS || showLanding) &&
                <CheckCurrentStatusWrap />
            }
            {(checkMyStatus?.request_status === appConstantsUGradAdmisssions?.CHECK_ADMISSION_PROCESS_STATUS_SUCCESS && !showLanding) &&
                <>



                    {/* {((!checkMyStatus?.request_data?.response?.hasGeneratedAcceptanceFeeInvoice && checkMyStatus?.request_data?.response?.isAdmitted && !showAcceptance) || showAdmissionCongrats) */}
                    {((!checkMyStatus?.request_data?.response?.hasGeneratedAcceptanceFeeInvoice && !showAcceptance) || showAdmissionCongrats)
                        && <AdmissionOfferedWrap setShowLanding={setShowLanding} setShowAdmissionCongrats={setShowAdmissionCongrats} setShowAcceptance={setShowAcceptance} admissionData={checkMyStatus?.request_data?.response} />
                    }
                    {showAcceptance &&

                        <PrintAcceptanceInvoiceWrap setShowSchoolFeeInvoice={setShowSchoolFeeInvoice} setShowAdmissionCongrats={setShowAdmissionCongrats} setShowLanding={setShowLanding} setShowAcceptance={setShowAcceptance} admissionData={checkMyStatus?.request_data?.response} />
                    }
                    {showSchoolFeeInvoice &&
                        <PrintSchoolFeeInvoiceWrap setShowSchoolFeeInvoice={setShowSchoolFeeInvoice} setShowDownloadAdmissionLetter={setShowDownloadAdmissionLetter} setShowAcceptance={setShowAcceptance} admissionData={checkMyStatus?.request_data?.response} />
                    }

                    {showDownloadAdmissionLetter &&
                        <PrintAdmissionLetterWrap setShowDownloadAdmissionLetter={setShowDownloadAdmissionLetter} setShowSchoolFeeInvoice={setShowSchoolFeeInvoice} admissionData={checkMyStatus?.request_data?.response} />
                    }


                </>
            }
        </div>
    )
}
const mapDispatchToProps = {
    getMyAdmissionStatus: uGradAdmissions.CheckMyAdmissionStatus,
};
const mapStateToProps = (state: any) => ({
    uGradCheckMyStatusRequest: state.allDdmissionsReducers.uGradCheckMyStatus,
})
export default connect(mapStateToProps, mapDispatchToProps)(CheckStatusLanding);