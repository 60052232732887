import React from "react";
import QRCode from "qrcode.react";

const QRCodeGenerator: React.FC<{ value: string }> = ({ value }) => {
  return (
    <div>
      <QRCode value={value} />
    </div>
  );
};

export default QRCodeGenerator;
