import { Link } from "react-router-dom";
import "./index.scss"

export const AppFooter = (props: any) => {
    let yearTxt = new Date().getFullYear();
    return(
        <div className="footer">
          <p> <span>&copy; {yearTxt}</span> IBB University. All rights reserved</p>
          <p>Developed by <Link to='https://gigbadi.com.ng/' target="_blank" className="footer-link">Gigbadi</Link></p>
        </div>
   
    )
}
