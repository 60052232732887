import React, { useEffect, useState } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import {
  getCourseRegistrationDetails,
  PrintCourseRegistration,
} from "../../../../redux/action/portal/undergraduate/PrintCourseReg";
import { LoadingItem } from "../../../../shared/components/loading";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const PrintCourseReg = ({ pageProps }: any) => {
  const screenResolution: any = useWindowSize();
  let authInfo = pageProps.uGradLoginRequest.request_data?.response?.profile;

  const [courseData, setCourseData] = useState<any>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getCourseHistory = async (payload: any) => {
    setLoading(true);
    try {
      const data = await getCourseRegistrationDetails(payload);
      setCourseData(data?.data);
      setError(null);
    } catch (err) {
      toast.error("An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const printCourseHistory = async (session: string) => {
    setLoading(true);
    try {
      const payload = {
        session,
        studentId: authInfo?.studentUniqueId,
      };

      const downloadResponse = await PrintCourseRegistration(payload);

      const downloadUrl = downloadResponse?.data;
      if (downloadResponse) {
        window.open(downloadUrl, "_blank");
      } else {
        setError("Download URL not found.");
      }
      setError(null);
    } catch (err) {
      toast.error("An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let payload = {
      studentId: authInfo?.studentUniqueId,
    };
    getCourseHistory(payload);
  }, []);

  return (
    <>
      {loading && <LoadingItem />}

      { !loading && (
    <div>
     <div className="previous_payments">
        {screenResolution?.width >= 767 && (
          <table className="course-table">
            <thead>
              <tr>
                <th>
                  {" "}
                  <span>SESSION</span>
                </th>
                <th>
                  {" "}
                  <span>LEVEL</span>
                </th>
                <th>
                  <span>DOWNLOAD</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {courseData.map((data: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>
                      <span>{data.session}</span>
                    </td>
                    <td>
                      {" "}
                      <span>{data.level}</span>
                    </td>
                    <td className="button-span">
                      <span>
                        {" "}
                        <button
                          className="download_cta btn"
                          onClick={() => printCourseHistory(data.session)}
                        >
                          Download
                        </button>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {screenResolution?.width <= 766 && (
          <div className="past_data">
            {courseData.map((data: any, index: any) => {
              return (
                <div className="each_history_data" key={index}>
                  <div className="other_data">
                    <div className="left">
                      <div className="title">Session</div>
                      <div className="value">{data.session}</div>
                    </div>
                    <div className="left">
                      <div className="title">Level</div>
                      <div className="value">{data.level}</div>
                    </div>
                    <div className="right">
                      <div className="value">
                        <button
                          className="download_cta btn"
                          onClick={() => printCourseHistory(data.session)} // Passing the session to the handler
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        </div>
        <div className="goback_cta">
        <Link to="/undergraduate/dashboard" className="btn">
          Back
        </Link>
        </div>
      </div>)}
      </>
      );
};

      export default PrintCourseReg;
