import { routes } from "../../../../services/paths";
import { ApiService } from "../../../../services/apiService";
import { handleRequestErrors } from "../../../../shared/utils";

export const fetchAllReceipt = async (StudentId: any, MatricNumber: any) => {

  return await ApiService.request({
    url: `${routes.PRINT_PREVIOUS_RECEIPT}?StudentId=${StudentId}&MatricNumber=${MatricNumber}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};