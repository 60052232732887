import React from "react";
import { useSelector } from "react-redux";
import Done from "../../../assets/Done.png";
import Cancel from "../../../assets/Cancel.png";
import Pending from "../../../assets/checkmark-xxl 1.png";

const ListOfUploads = () => {
  const { documents, clearanceRequests, documentHistory } = useSelector(
    (state: any) => state.eDocumentation
  );
  const requests = [...documentHistory, ...clearanceRequests];
  const checkStatus = (document: string) => {
    const doc = requests.find(
      (request: any) =>
        request.documentType.toLowerCase() === document.toLowerCase()
    );
    if (doc && doc?.status === "APPROVED") {
      return Done;
    } else if (doc && doc?.status === "REJECTED") {
      return Cancel;
    } else if (doc && doc?.status === "PENDING") {
      return Pending;
    }
    return "not-uploaded";
  };
  return (
    <div className="docs-list">
      {documents.map((document: string) => (
        <div className="document-type-update">
          <p>{document}</p>
          {checkStatus(document) === "not-uploaded" ? null : (
            <img src={checkStatus(document)} alt="done" />
          )}
        </div>
      ))}
    </div>
  );
};

export default ListOfUploads;
