import { useCallback, useEffect, useState } from "react";

import {
  bookAccomodation,
  getAvailableAccomodations,
} from "../../../../redux/action/hostels";
import Site from "../Site";
import { LoadingItem } from "../../../../shared/components/loading";
import Terms from "../Terms";
import { history } from "../../../../shared/_helpers/history";
import { toast } from "react-toastify";
import { uGradPortal } from "../../../../redux/action/portal/undergraduate";
import { useDispatch } from "react-redux";

export interface Room {
  roomName: string;
  roomUniqueId: string;
}

export interface Block {
  blockName: string;
  blockUniqueId: string;
  rooms: Room[];
}

interface SingleSite {
  siteName: string;
  blocks: Block[];
}

const SiteList = ({ student }: any) => {
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedBlockName, setSelectedBlockName] = useState("");
  const [roomName, setRoomName] = useState("");
  const [availableHostels, setAvailableHostels] = useState<SingleSite[]>([]);
  const [loading, setLoading] = useState(false);
  const [noRooms, setNoRooms] = useState("");
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [selectedSiteIndex, setSelectedSiteIndex] = useState<number | null>(
    null
  );
  const [isBooking, setIsBooking] = useState(false);

  const dispatch = useDispatch();

  const bookingPayload = {
    studentId: student?.studentUniqueId,
    matricNumber: student?.matricNumber,
    hostelSite: selectedSite,
    blockName: selectedBlockName,
    rooomName: roomName,
    roomUniqueId: selectedRoom,
    blockUniqueId: selectedBlock,
  };

  const getAllAvailableAccomodations = useCallback(
    async (studentId: string) => {
      setNoRooms("");
      setLoading(true);
      const res = await getAvailableAccomodations(studentId);
      if (res?.data?.length) {
        setAvailableHostels(res.data);
      } else if (res?.response?.status === 404) {
        setNoRooms(res?.response?.data?.message);
      } else if (
        res?.response?.data?.includes(
          "Hostel accommodation booking has not yet opened."
        )
      ) {
        setNoRooms("Hostel accommodation booking has not yet opened.");
      }
      setLoading(false);
    },
    []
  );

  useEffect(() => {
    getAllAvailableAccomodations(student?.studentUniqueId);
  }, [getAllAvailableAccomodations, student?.studentUniqueId]);

  const handleRoomSelect = (room: any) => {
    setInputError(false);
    const selectedRoomSite = availableHostels.find(
      (site: any) => site.siteName === selectedSite
    );
    const block = selectedRoomSite?.blocks?.find(
      (block: any) => block?.blockUniqueId === selectedBlock
    );
    if (block?.rooms) {
      const roomName =
        block.rooms.find((el: Room) => el?.roomUniqueId === room)?.roomName ||
        "";
      setRoomName(roomName);
    }
    setSelectedRoom(room);
  };
  const handleBlockChange = (
    event: any,
    blockSite: any,
    sitedIndex: number
  ) => {
    setSelectedRoom("");
    setSelectedSiteIndex(sitedIndex);
    const block = event.target.value;
    const selectedRoomSite = availableHostels.find(
      (site: any) => site.siteName === blockSite
    );

    if (selectedRoomSite?.blocks) {
      const blockName =
        selectedRoomSite.blocks?.find((el: any) => el?.blockUniqueId === block)
          ?.blockName || "";
      setSelectedBlockName(blockName);
    }
    setSelectedSite(blockSite);
    setSelectedBlock(block);
  };

  const handleProceed = (index: number) => {
    if (!selectedBlock || !selectedRoom) {
      setSelectedSiteIndex(index);
      return setInputError(true);
    }
    setShowTermsModal(true);
  };

  const handleSubmitBooking = async () => {
    setIsBooking(true);
    const res = await bookAccomodation(bookingPayload);
    await dispatch(
      uGradPortal.UnderGradProfile({ matricNumber: student?.matricNumber })
    );
    setIsBooking(false);
    setShowTermsModal(false);
    if (res?.status === 200) {
      history.push("/undergraduate/hostel-fee");
    } else if (res?.response?.status < 400) {
      toast.error(res?.response?.data);
    }
  };

  return (
    <div className="site-list">
      <Terms
        show={showTermsModal}
        setShow={setShowTermsModal}
        handleSubmitBooking={handleSubmitBooking}
        loading={isBooking}
      />
      {loading ? (
        <LoadingItem />
      ) : availableHostels?.length ? (
        availableHostels.map((site: SingleSite, index) => (
          <Site
            key={index}
            site={site}
            siteIndex={index}
            selectedRoom={selectedRoom}
            selectedSite={selectedSite}
            selectedBlock={selectedBlock}
            setSelectedBlock={setSelectedBlock}
            onRoomSelect={handleRoomSelect}
            handleBlockChange={handleBlockChange}
            handleProceed={handleProceed}
            setShowTermsModal={setShowTermsModal}
            inputError={inputError}
            selectedSiteIndex={selectedSiteIndex}
          />
        ))
      ) : noRooms?.length ? (
        <h3>{noRooms}</h3>
      ) : null}
    </div>
  );
};

export default SiteList;
