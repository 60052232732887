// import { CLEAR_STATE } from "../../action-constants/admissions";
import * as types from "../../action-constants/edocumentation";

const initialState = {
  documents: [],
  uploadingDoc: false,
  clearanceRequests: [],
  documentHistory: [],
  paymentStatus:'',
};

export default function eDocumentation(state = initialState, action: any) {
  switch (action.type) {
    case types.GET_EDOCUMENTATION_UNDERGRADUATE:
      return {
        ...state,
        ...action.payload,
      };
    case types.GET_EDOCUMENT_TYPES:
      return {
        ...state,
        documents: action.payload,
      };

    case types.UPLOADING_EDOCUMENTATION_DOC:
      return {
        ...state,
        uploadingDoc: action.payload,
      };
    case types.CLEARANCE_REQUESTS:
      return {
        ...state,
        clearanceRequests: action.payload,
      };
    case types.DOCUMENTS_HISTORY:
      return {
        ...state,
        documentHistory: action.payload,
      };
    case types.CLEAR_STATE:
      return initialState
    default:
      return state;
  }
}
