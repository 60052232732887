import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CheckCurrentPostUmeStatusWrap from "./components/check-postume-status"
import "./index.scss";

import { uGradAdmissions } from "../../../../redux/action/admissions/undergraduate"
import { appConstantsUGradAdmisssions } from "../../../../redux/action-constants/admissions/undergraduate"

const getAdmissionStatus = async ({ pageProps, payload }: any) => {

    await pageProps.getMyAdmissionStatus(payload);
}


const CheckPostUmeStatusLanding = (pageProps: any) => {

    const [showLanding, setShowLanding] = useState<boolean>(true);
    

    let checkMyStatus = pageProps?.uGradCheckMyStatusRequest
    useEffect(() => {


        getAdmissionStatus({
            pageProps,
            payload: "CLEAR"
        })


    }, [])

    useEffect(() => {

        if (checkMyStatus?.request_status === appConstantsUGradAdmisssions?.CHECK_ADMISSION_PROCESS_STATUS_SUCCESS
        ) {
            setShowLanding(false)

            

        }






    }, [checkMyStatus?.request_status])
    return (
        <div className="status_landing_Wrap">
            {/* {(checkMyStatus?.request_status !== appConstantsUGradAdmisssions?.CHECK_ADMISSION_PROCESS_STATUS_SUCCESS || showLanding) &&
            // {(checkMyStatus?.request_status !== appConstantsUGradAdmisssions?.CHECK_ADMISSION_PROCESS_STATUS_SUCCESS || showLanding) && */}
                <CheckCurrentPostUmeStatusWrap />
            {/* } */}
            
        </div>
    )
}
const mapDispatchToProps = {
    getMyAdmissionStatus: uGradAdmissions.CheckMyAdmissionStatus,
};
const mapStateToProps = (state: any) => ({
    uGradCheckMyStatusRequest: state.allDdmissionsReducers.uGradCheckMyStatus,
})
export default connect(mapStateToProps, mapDispatchToProps)(CheckPostUmeStatusLanding);