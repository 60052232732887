import { Link } from "react-router-dom";
import "./navigation.scss";
import undo from "../../../assets/Undo.png";

const Navigation = ({ to }) => {
  return (
    <>
      <Link className="back-link" to={to}>
        <span>
          <img className="icon" src={undo} alt="Icon" />
          <p>Back to Dashboard</p>
        </span>
      </Link>
    </>
  );
};

export default Navigation;
