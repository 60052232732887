import InvoiceContent from "./InvoiceContent";
import { UndergraduatePortal } from "../../../shared/templates/portal/undergraduate";

const Invoice = () => {
  return (
    <UndergraduatePortal
      title="Hostel accommodation"
      childComponent={<InvoiceContent />}
    />
  );
};

export default Invoice;
