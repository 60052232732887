import React from "react";
import Header from "../../landing/header";
import "./index.scss";
import { Link } from "react-router-dom";

const FAQs = () => {
  let yearTxt = new Date().getFullYear();
  return (
    <div className="landing-page-container">
      <div className="landing-page">
        <Header pageTitle="Frequently Asked Questions (FAQ)" />
        <div className="main-section">
          {/* <div className="main-section-heading">
            <div className="black-dot dot"></div>{" "}
            <h5>Having Problem Or Need Clarification?</h5>
          </div> */}
          <div className="main-section-content">
            <p>
              If you have any problem on the portal as regards your online
              registration, just go through the following FAQ, you might find an
              answer to your problem. If this page is not helpful, then use the
              contact page on the University's main website to submit your
              complain or visit the University computer center for further
              assistance.
            </p>
            <div className="questions">
              <strong>
                (Q 1.) I have purchased/receive my online registration access
                PIN but don't know how to log in and make my registration.
              </strong>
              <p>
                <strong>Answer :</strong>
                You must create and account first before you can log in and make
                your online registration. Click on the "START NEW ONLINE
                REGISTRATION" link to begin OR the "Student online portal" Link
                for a detailed guideline.
              </p>

              <strong>
                (Q 2.) I have submitted my registration but want to change some
                information on my submission. Can i edit my registration ?
              </strong>
              <p>
                <strong>Answer :</strong>
                Yes, you can come back for an unlimited number of times to edit
                and re-submit your registration as long as it is within the
                registration period.
              </p>

              <strong>
                (Q 3.) If i forgot my login username or password, can i retrieve
                them ?
              </strong>
              <p>
                <strong>Answer :</strong>
                Yes, you can retrieve your login username or password by
                reporting to the officer in charge at the University computer
                center with your registration details.
              </p>

              <strong>
                (Q 4.) My PIN is incomplete or unreadable and i cant read the
                PIN. What should i do ?
              </strong>
              <p>
                <strong>Answer :</strong>
                Report to the officer in charge at the University computer
                center with your registration details. Your PIN will be
                retrieved using your Serial No.
              </p>

              <strong>
                (Q 5.) I keep on getting an error message "Invalid PIN". What
                does this mean ?
              </strong>
              <p>
                <strong>Answer :</strong>
                It means your PIN is not in the Portal database. Report to the
                officer in charge at the computer center with your PIN to
                validate the authenticity of your PIN.
              </p>

              <strong>
                (Q 6.) I keep on getting an error message "PIN Already Used".
                What does this mean ?
              </strong>
              <p>
                <strong>Answer :</strong>
                It means the PIN you are attempting to use is already used by
                another student. Report to the officer in charge at the
                University computer center with your PIN to validate the
                authenticity of your PIN.
              </p>

              <strong>
                (Q 7.)I keep on getting an error message "An illegal choice has
                been detected. Please contact the site administrator". What does
                this mean ?
              </strong>
              <p>
                <strong>Answer :</strong>
                It means that you are attempting save your information without
                selecting the values of a sequential combo boxes accordingly
                (e.g Nationality, State, LGA OR the School, Department &
                Programme Values). Select the boxes accordingly and re-submit
                your information.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="footer">
          <p>Ibrahim Badamasi Babangida University</p>
        </div> */}
        <div className="footer">
          <p>
            {" "}
            <span>&copy; {yearTxt}</span> IBB University. All rights reserved
          </p>
          <p>
            Developed by{" "}
            <Link
              to="https://gigbadi.com.ng/"
              target="_blank"
              className="footer-link"
            >
              Gigbadi
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
