import { ApiService } from "../../../services/apiService";
import { routes } from "../../../services/paths";
import { handleRequestErrors } from "../../../shared/utils";

export const getAvailableAccomodations = async (studentUniqueId: string) => {
  return await ApiService.request({
    url: `${routes.AVAILABLE_ACCOMODATION}`,
    http_method: "POST",
    data: { studentUniqueId },
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const checkEligibility = async (studentUniqueId: string) => {
  return await ApiService.request({
    url: `${routes.CHECK_ELIGIBILITY}`,
    http_method: "POST",
    data: { studentUniqueId },
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const bookAccomodation = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.BOOK_ACCOMODATION}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getOrGenerateHostelFee = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.GENERATE_HOSTEL_FEE}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getStudentHostelBooking = async (studentUniqueId: any) => {
  return await ApiService.request({
    url: `${routes.ACCOMODATION_BOOKING}`,
    http_method: "POST",
    data: { studentUniqueId },
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const accomodationConfirmation = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.ACCOMODATION_CONFIRMATION}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getCurrentSchoolSession = async () => {
  return await ApiService.request({
    url: `${routes.GET_CURRENT_SESSION}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

