import AppHeader from "../../../components/headers/ugrad-portal";
import { AppFooter } from "../../../components/admissions/footer";
import { ChildrenCompProps } from "../../../../types";
import "./index.scss";
import StudentExamCard from "../../../../modules/exam-card";

export const UndergraduatePortal: React.FC<ChildrenCompProps> = (props) => {
  const { childComponent } = props;
  return (
    <div className="undergraduate_portal_wrap">
      <StudentExamCard />
      <div className="page_wrap">
        <AppHeader title={props.title} />

        <div className="portal_content">{childComponent}</div>
      </div>
      <AppFooter />
    </div>
  );
};
