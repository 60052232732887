
export const allStatus = [
    {
        status: "CARD USAGE LIMIT EXCEEDED",
        stepsMessages: [
            "Purchase a new scratch card or token.",
            "Use it once to check your result on the relevant portal (WAEC, NECO, etc.).",
            "If successful,",
            "Log in to the IBBU Post-UTME Portal at postutme.ibbu.edu.ng.",
            "Accurately enter your card details along with your exam information.",
            "Print your acknowledgment slip and verify that all entries are correct."],
        notesMessages: [
            "If you cannot check your result with the new card, do not submit it, as this may lead to disqualification.",
            "Candidates using WAEC, NABTEB, and NBAIS should enter both the scratch card PIN and card serial numbers. NECO candidates should only enter the token, no serial number is required.",
            "If you have two results, repeat the steps (1-6) for the Second Result"
        ],
        shouldDownload: true
    },
    {
        status: "FALSIFICATION OF RESULT",
        messageOnly: "Disqualification",
        shouldDownload: true,
        shouldHide: true
    },
    {
        status: "INDIGENESHIP AND NECO RESULT UNDER INVESTIGATION",
        messageOnly: "Check back on 20th October, 2024 for your status",
        shouldDownload: true
    },
    {
        status: "INVALID EXAM DETAILS",
        stepsMessages: [
            "Obtain a new scratch card.",
            "Use it once to check your result on the relevant portal (WAEC, NECO, etc.).",
            "If successful,",
            "Log in to the IBBU Post-UTME Portal at postutme.ibbu.edu.ng.",
            "Carefully enter both your exam and card details.",
            "Print your acknowledgment slip and verify that all entries are correct."],
        notesMessages: [
            "If you cannot check your result with the new card, do not submit it, as this may lead to disqualification.",
            "Candidates using WAEC, NABTEB, and NBAIS should enter both the scratch card PIN and card serial numbers. NECO candidates should only enter the token, no serial number is required.",
            "If you have two results, repeat the steps (1-6) for the Second Result"
        ],
        shouldDownload: true
    },
    {
        status: "INVALID SCRATCH CARD OR TOKEN DETAILS",
        stepsMessages: [
            "Purchase a new scratch card or token.",
            "Use it once to check your result on the relevant portal (WAEC, NECO, etc.).",
            "If successful,",
            "Log in to the IBBU Post-UTME Portal at postutme.ibbu.edu.ng.",
            "Accurately enter your card details along with your exam information.",
            "Print your acknowledgment slip and verify that all entries are correct."],
        notesMessages: [
            "If you cannot check your result with the new card, do not submit it, as this may lead to disqualification.",
            "Candidates using WAEC, NABTEB, and NBAIS should enter both the scratch card PIN and card serial numbers. NECO candidates should only enter the token, no serial number is required.",
            "If you have two results, repeat the steps (1-6) for the Second Result"
        ],
        shouldDownload: true
    },
    {
        status: "NAME DISCREPANCY",
        stepsMessages: [
            "Print your O'Level result(s).",
            "Print your UTME/DE registration slip.",
            "Compare the names on both documents to identify any discrepancies.",
            "Visit the NIN and JAMB offices to correct your name as it appears on your O'Level result."],
        notesMessages: [
            "If you cannot check your result with the new card, do not submit it, as this may lead to disqualification.",
            "Candidates using WAEC, NABTEB, and NBAIS should enter both the scratch card PIN and card serial numbers. NECO candidates should only enter the token, no serial number is required.",
            "If you have two results, repeat the steps (1-6) for the Second Result"
        ],
        shouldDownload: true
    },
    {
        status: "NAME MISMATCH",
        messageOnly: "Kindly Review your Exam details, and resubmit a New Card on the post UTME portal (use once before submitting)",
        shouldDownload: true
    },
    // {
    //     status: "RESULT VERIFICATION REPORT UNDER REVIEW",
    //     shouldDownload: true,
    //     shouldHide: true
    // },
    {
        status: "USED CARD",
        stepsMessages: [
            "Purchase a new scratch card or token.",
            "Use it once to check your result on the relevant portal (WAEC, NECO, etc.).",
            "If successful,",
            "Log in to the IBBU Post-UTME Portal at postutme.ibbu.edu.ng.",
            "Accurately enter your card details along with your exam information.",
            "Print your acknowledgment slip and verify that all entries are correct."],
        notesMessages: [
            "If you cannot check your result with the new card, do not submit it, as this may lead to disqualification.",
            "Candidates using WAEC, NABTEB, and NBAIS should enter both the scratch card PIN and card serial numbers. NECO candidates should only enter the token, no serial number is required.",
            "If you have two results, repeat the steps (1-6) for the Second Result"
        ],
        shouldDownload: true
    },
    {
        status: "YOU ARE NO LONGER A CANDIDATE OF IBBU",
        messageOnly: "Best of Luck",
        shouldHide: true
    },
    
    {
        status: "Your results have been confirmed, and you are in good standing. However, You are Yet to Upload Your O'Level Result on JAMB CAPS.",
        messageOnly: "Your results have been confirmed, and you are in good standing. However, You are Yet to Upload Your O'Level Result on JAMB CAPS. Kindly visit any JAMB office or the nearest JAMB CBT center to upload your O'Level result(s) on JAMB CAPS.",
        shouldHide: true,
        shouldDownload: true,
    },
    {
        status: "Your results have been confirmed, and you are in good standing. However, You are yet to Accept your Admission on JAMB CAPS.",
        messageOnly: "Your results have been confirmed, and you are in good standing. However, You are yet to Accept your Admission on JAMB CAPS. Kindly visit JAMB CAPS to Accept and Print your JAMB Admission.",
        shouldHide: true,
        shouldDownload: true,
    },
    {
        status: "Your results have been confirmed, and you are in good standing. However, you have been offered a course different from your original choice on JAMB CAPS.",
        messageOnly: "Your results have been confirmed, and you are in good standing. However, you have been offered a course different from your original choice on JAMB CAPS. If you accept the offer, kindly visit JAMB CAPS to accept it.",
        shouldHide: true,
        shouldDownload: true,
    },
    {
        status: "Your results have been confirmed, and you are in good standing. Kindly check JAMB CAPS for your admission Status.",
        messageOnly: "Your results have been confirmed, and you are in good standing. Kindly check JAMB CAPS for your admission Status.",
        shouldHide: true,
        shouldDownload: true,
    },
    {
        status: "RESULT VERIFICATION REPORT UNDER REVIEW",
        messageOnly: "RESULT VERIFICATION REPORT UNDER REVIEW. Please print the general instructions for further guidance and revisit the portal to check your status on Monday 18th November, 2024.",
        shouldDownload: true,
        shouldHide: true
    },
    {
        status: "Your results have been confirmed, and you are in good standing. However, you are required to print Your JAMB Admission.",
        messageOnly: "Your results have been confirmed, and you are in good standing. However, you are required to print Your JAMB Admission. Kindly Print your JAMB Admission Letter from JAMB CAPS.",
        shouldHide: true,
        shouldDownload: true,
    },
    {
        status: "CARD USAGE LIMIT EXCEEDED: RESPONDED",
        messageOnly: "Your New post UTME Entries have been Received,Please kindly Check back from 18th November, 2024 for Your New Status",
        shouldHide: true,
        shouldDownload: true,
    },
    {
        status: "INVALID CARD DETAILS: RESPONDED",
        messageOnly: "Your New post UTME Entries have been Received,Please kindly Check back from 18th November, 2024 for Your New Status",
        shouldHide: true,
        shouldDownload: true,
    },
    {
        status: "INVALID EXAM DETAILS: RESPONDED",
        messageOnly: "Your New post UTME Entries have been Received,Please kindly Check back from 18th November, 2024 for Your New Status",
        shouldHide: true,
        shouldDownload: true,
    },
    {
        status: "USED CARD: RESPONDED",
        messageOnly: "Your New post UTME Entries have been Received,Please kindly Check back from 18th November, 2024 for Your New Status",
        shouldHide: true,
        shouldDownload: true,
    },
    {
        status: "CARD USAGE LIMIT EXCEEDED: RESPONDED AND ADMITTED ON CAPS",
        messageOnly: "Your new Post-UTME entries have been received.Please check back from 18th November 2024 for your updated status. While awaiting your verification status, kindly visit JAMB CAPS to accept your admission offer.",
        shouldHide: true,
        shouldDownload: true,
    },
    {
        status: "INVALID CARD DETAILS: RESPONDED AND ADMITTED ON CAPS",
        messageOnly: "Your new Post-UTME entries have been received.Please check back from 18th November 2024 for your updated status. While awaiting your verification status, kindly visit JAMB CAPS to accept your admission offer.",
        shouldHide: true,
        shouldDownload: true,
    },
    {
        status: "INVALID EXAM DETAILS: RESPONDED AND ADMITTED ON CAPS",
        messageOnly: "Your new Post-UTME entries have been received.Please check back from 18th November 2024 for your updated status. While awaiting your verification status, kindly visit JAMB CAPS to accept your admission offer.",
        shouldHide: true,
        shouldDownload: true,
    },
    {
        status: "USED CARD: RESPONDED  AND ADMITTED ON CAPS",
        messageOnly: "Your new Post-UTME entries have been received.Please check back from 18th November 2024 for your updated status. While awaiting your verification status, kindly visit JAMB CAPS to accept your admission offer.",
        shouldHide: true,
        shouldDownload: true,
    },
]
