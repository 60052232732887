import { useEffect, useState } from "react";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { LoadingItem } from "../../../../shared/components/loading";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { fetchAllReceipt } from "../../../../redux/action/portal/undergraduate/PaymentHistory";
import "./index.scss";
import MobileTable from "./components/MobileTable";
import DesktopTable from "./components/DesktopTable";
import ReactPaginate from "react-paginate";

const PaymentHistory = ({ pageProps }: any) => {
  const screenResolution: any = useWindowSize();
  let authInfo = pageProps.uGradLoginRequest.request_data?.response?.profile;
  const [paymentDetails, setPaymentDetails] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const itemsPerPage = 10;
  const pageCount = Math.ceil(paymentDetails.length / itemsPerPage);

  const paginatedData = paymentDetails.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected);
  };

  const getPaymentHistory = async (payload: any) => {
    const { StudentId, MatricNumber } = payload;
    setLoading(true);
    try {
      const data = await fetchAllReceipt(StudentId, MatricNumber);
      setPaymentDetails(data?.data);
    } catch (err) {
      toast.error("An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let payload = {
      StudentId: authInfo?.studentUniqueId,
      MatricNumber: authInfo?.matricNumber,
    };
    getPaymentHistory(payload);
  }, []);

  return (
    <>
      {loading && <LoadingItem />}

      {!loading && (
        <div className="payment-history-table">
          <div className="previous_payments">
            {screenResolution?.width >= 767 && (
              <DesktopTable
                currentPage={currentPage}
                paymentDetails={paginatedData}
                handlePageClick={handlePageClick}
              />
            )}

            {screenResolution?.width <= 766 && (
              <MobileTable
                currentPage={currentPage}
                paymentDetails={paginatedData}
                handlePageClick={handlePageClick}
              />
            )}
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            containerClassName="payment_history_pagination"
            pageClassName="page_num"
            pageLinkClassName="page_link"
            activeClassName="active_page_link"
            previousClassName="previous_page_link"
            nextClassName="next_page_link"
          />
          <div className="goback_cta">
            <Link to="/undergraduate/dashboard" className="btn">
              Back
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentHistory;
