import SideBar from "./SideBar";
import { LoadingItem } from "../../../shared/components/loading";
import { useWindowSize } from "../../../hooks/useWindowSize";

const PageWrapper = ({ student, children }: any) => {
  const screenResolution: any = useWindowSize();
  return (
    <div className="e-documentation-page-wrapper">
      <div className="e-documentation-content">
        {student?.studentUniqueId ? (
          <>
            {screenResolution?.width >= 850 ? (
              <SideBar student={student} />
            ) : null}

            <div className="main-page">{children}</div>
          </>
        ) : (
          <LoadingItem />
        )}
      </div>
    </div>
  );
};

export default PageWrapper;
