import {
    UGradProfileReducer,
    UGradGenerateAcceptanceFeeReducer,
    UGradGstFeeReducer,
    UGradGstNanissReducer,
    UGradSchoolFeeReducer,
    UGradSugFeeReducer,
    UGradFacultyFeeReducer,
    UGradEntrepreneurFeeReducer,
    UGradLoadPresetCoursesReducer,
    UGradSavePresetCoursesReducer,
    UnderGradDeletePresetCourseReducer,
    UnderGradPrintCourseReducer,
    UnderGradPrintExamCardReducer,
    UnderGradGetBioDataReducer,
    UnderGradPrintBioDataReducer,
    UnderGradUpdateBioDataReducer,
    ChangePasswordReducer
} from "./ugrad-portal"



export const portalReducers = {
    UGradProfileReducer,
    UGradGenerateAcceptanceFeeReducer,
    UGradGstFeeReducer,
    UGradGstNanissReducer,
    UGradSchoolFeeReducer,
    UGradSugFeeReducer,
    UGradFacultyFeeReducer,
    UGradEntrepreneurFeeReducer,
    UGradLoadPresetCoursesReducer,
    UGradSavePresetCoursesReducer,
    UnderGradDeletePresetCourseReducer,
    UnderGradPrintCourseReducer,
    UnderGradPrintExamCardReducer,
    UnderGradGetBioDataReducer,
    UnderGradPrintBioDataReducer,
    UnderGradUpdateBioDataReducer,
    ChangePasswordReducer
}