export const appConstantsUGradOPortal ={

    GET_PROFILE_DATA_SUCCESS : 'GET_PROFILE_DATA_SUCCESS',
    GET_PROFILE_DATA_PENDING : 'GET_PROFILE_DATA_PENDING',
    GET_PROFILE_DATA_FAILURE : 'GET_PROFILE_DATA_FAILURE',
    GET_PROFILE_DATA_RESET : 'GET_PROFILE_DATA_RESET',


    GENERATE_SCHOOLFEE_SUCCESS : 'GENERATE_SCHOOLFEE_SUCCESS',
    GENERATE_SCHOOLFEE_PENDING : 'GENERATE_SCHOOLFEE_PENDING',
    GENERATE_SCHOOLFEE_FAILURE : 'GENERATE_SCHOOLFEE_FAILURE',
    GENERATE_SCHOOLFEE_RESET : 'GENERATE_SCHOOLFEE_RESET',

    GENERATE_GSTFEE_SUCCESS : 'GENERATE_GSTFEE_SUCCESS',
    GENERATE_GSTFEE_PENDING : 'GENERATE_GSTFEE_PENDING',
    GENERATE_GSTFEE_FAILURE : 'GENERATE_GSTFEE_FAILURE',
    GENERATE_GSTFEE_RESET : 'GENERATE_GSTFEE_RESET',

    GENERATE_NANISS_SUCCESS : 'GENERATE_NANISS_SUCCESS',
    GENERATE_NANISS_PENDING : 'GENERATE_NANISS_PENDING',
    GENERATE_NANISS_FAILURE : 'GENERATE_NANISS_FAILURE',
    GENERATE_NANISS_RESET : 'GENERATE_NANISS_RESET',

    GENERATE_SUGFEE_SUCCESS : 'GENERATE_SUGFEE_SUCCESS',
    GENERATE_SUGFEE_PENDING : 'GENERATE_SUGFEE_PENDING',
    GENERATE_SUGFEE_FAILURE : 'GENERATE_SUGFEE_FAILURE',
    GENERATE_SUGFEE_RESET : 'GENERATE_SUGFEE_RESET',

    GENERATE_FACULTYFEE_SUCCESS : 'GENERATE_FACULTYFEE_SUCCESS',
    GENERATE_FACULTYFEE_PENDING : 'GENERATE_FACULTYFEE_PENDING',
    GENERATE_FACULTYFEE_FAILURE : 'GENERATE_FACULTYFEE_FAILURE',
    GENERATE_FACULTYFEE_RESET : 'GENERATE_FACULTYFEE_RESET',

    GENERATE_ACCEPTANCEFEE_RECEIPT_SUCCESS : 'GENERATE_ACCEPTANCEFEE_RECEIPT_SUCCESS',
    GENERATE_ACCEPTANCEFEE_RECEIPT_PENDING : 'GENERATE_ACCEPTANCEFEE_RECEIPT_PENDING',
    GENERATE_ACCEPTANCEFEE_RECEIPT_FAILURE : 'GENERATE_ACCEPTANCEFEE_RECEIPT_FAILURE',
    GENERATE_ACCEPTANCEFEE_RECEIPT_RESET : 'GENERATE_ACCEPTANCEFEE_RECEIPT_RESET',

    GENERATE_ENT_FEE_SUCCESS : 'GENERATE_ENT_FEE_SUCCESS',
    GENERATE_ENT_FEE_PENDING : 'GENERATE_ENT_FEE_PENDING',
    GENERATE_ENT_FEE_FAILURE : 'GENERATE_ENT_FEE_FAILURE',
    GENERATE_ENT_FEE_RESET : 'GENERATE_ENT_FEE_RESET',


    UGRAD_LOAD_PRESET_COURSES_SUCCESS : 'UGRAD_LOAD_PRESET_COURSES_SUCCESS',
    UGRAD_LOAD_PRESET_COURSES_PENDING : 'UGRAD_LOAD_PRESET_COURSES_PENDING',
    UGRAD_LOAD_PRESET_COURSES_FAILURE : 'UGRAD_LOAD_PRESET_COURSES_FAILURE',
    UGRAD_LOAD_PRESET_COURSES_RESET : 'UGRAD_LOAD_PRESET_COURSES_RESET',

    SAVE_STUDENT_COURSES_REG_SUCCESS : 'SAVE_STUDENT_COURSES_REG_SUCCESS',
    SAVE_STUDENT_COURSES_REG_PENDING : 'SAVE_STUDENT_COURSES_REG_PENDING',
    SAVE_STUDENT_COURSES_REG_FAILURE : 'SAVE_STUDENT_COURSES_REG_FAILURE',
    SAVE_STUDENT_COURSES_REG_RESET : 'SAVE_STUDENT_COURSES_REG_RESET',


    PRINT_EXAM_CARD_SUCCESS : 'PRINT_EXAM_CARD_SUCCESS',
    PRINT_EXAM_CARD_PENDING : 'PRINT_EXAM_CARD_PENDING',
    PRINT_EXAM_CARD_FAILURE : 'PRINT_EXAM_CARD_FAILURE',
    PRINT_EXAM_CARD_RESET : 'PRINT_EXAM_CARD_RESET',


    DELETE_PRESET_COURSE_REG_SUCCESS : 'DELETE_PRESET_COURSE_REG_SUCCESS',
    DELETE_PRESET_COURSE_REG_PENDING : 'DELETE_PRESET_COURSE_REG_PENDING',
    DELETE_PRESET_COURSE_REG_FAILURE : 'DELETE_PRESET_COURSE_REG_FAILURE',
    DELETE_PRESET_COURSE_REG_RESET : 'DELETE_PRESET_COURSE_REG_RESET',

    PRINT_COURSE_REG_SUCCESS : 'PRINT_COURSE_REG_SUCCESS',
    PRINT_COURSE_REG_PENDING : 'PRINT_COURSE_REG_PENDING',
    PRINT_COURSE_REG_FAILURE : 'PRINT_COURSE_REG_FAILURE',
    PRINT_COURSE_REG_RESET : 'PRINT_COURSE_REG_RESET',

    GET_STUDENT_BIO_DATA_SUCCESS : 'GET_STUDENT_BIO_DATA_SUCCESS',
    GET_STUDENT_BIO_DATA_PENDING : 'GET_STUDENT_BIO_DATA_PENDING',
    GET_STUDENT_BIO_DATA_FAILURE : 'GET_STUDENT_BIO_DATA_FAILURE',
    GET_STUDENT_BIO_DATA_RESET : 'GET_STUDENT_BIO_DATA_RESET',

    PRINT_STUDENT_BIO_DATA_SUCCESS : 'PRINT_STUDENT_BIO_DATA_SUCCESS',
    PRINT_STUDENT_BIO_DATA_PENDING : 'PRINT_STUDENT_BIO_DATA_PENDING',
    PRINT_STUDENT_BIO_DATA_FAILURE : 'PRINT_STUDENT_BIO_DATA_FAILURE',
    PRINT_STUDENT_BIO_DATA_RESET : 'PRINT_STUDENT_BIO_DATA_RESET',

    UPDATE_STUDENT_BIO_DATA_SUCCESS : 'UPDATE_STUDENT_BIO_DATA_SUCCESS',
    UPDATE_STUDENT_BIO_DATA_PENDING : 'UPDATE_STUDENT_BIO_DATA_PENDING',
    UPDATE_STUDENT_BIO_DATA_FAILURE : 'UPDATE_STUDENT_BIO_DATA_FAILURE',
    UPDATE_STUDENT_BIO_DATA_RESET : 'UPDATE_STUDENT_BIO_DATA_RESET',

    
    RESET_STUDENT_PASSWORD_SUCCESS : 'RESET_STUDENT_PASSWORD_SUCCESS',
    RESET_STUDENT_PASSWORD_PENDING : 'RESET_STUDENT_PASSWORD_PENDING',
    RESET_STUDENT_PASSWORD_FAILURE : 'RESET_STUDENT_PASSWORD_FAILURE',
    RESET_STUDENT_PASSWORD_RESET : 'RESET_STUDENT_PASSWORD_RESET',

    CHANGE_STUDENT_PASSWORD_SUCCESS : 'CHANGE_STUDENT_PASSWORD_SUCCESS',
    CHANGE_STUDENT_PASSWORD_PENDING : 'CHANGE_STUDENT_PASSWORD_PENDING',
    CHANGE_STUDENT_PASSWORD_FAILURE : 'CHANGE_STUDENT_PASSWORD_FAILURE',
    CHANGE_STUDENT_PASSWORD_RESET : 'CHANGE_STUDENT_PASSWORD_RESET',
}