import Modal from 'react-bootstrap/Modal'
import PDF from "../../../../../assets/pdf.png"
import ExportIc from "../../../../../assets/export.png"
import CloseIc from "../../../../../assets/close.png"

export const DownloadModal = ({ setShowDwModal, isDwModalShown, downloadLink }: any) => {

    let link = downloadLink.split('/').pop()
    return (
        <Modal className="download_container" show={isDwModalShown} onHide={() => setShowDwModal(false)}>
            <Modal.Body>
                <div className="download_wrap">
                    <img src={CloseIc} alt="" className="close_cta" onClick={()=>setShowDwModal(false)} />
                    <div className="export_ic">
                        <img src={ExportIc} alt="" />
                    </div>
                    <div className="link_wrap">
                        <img src={PDF} alt="" />
                        <div className="link_txt">
                            {link}
                        </div>
                    </div>
                    <div className="dw_cta">
                        <a href={downloadLink}>Download</a>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}