import { useSelector } from "react-redux";
import "./index.scss";

const IbbuHeader = () => {
  const studentDetails = useSelector(
    (state: any) => state.allOnboardingReducers?.uGradLogin
  );
  const currentSemester =
    studentDetails?.request_data?.response?.profile?.currentSemester || "";
  const currentSession =
    studentDetails?.request_data?.response?.profile?.currentSession || "";

  return (
    <div>
      <div className="front-page-headers">
        <img src="https://i.ibb.co/GspYCGX/logo2.png" alt="school-logo" />
        <h2>IBRAHIM BADAMASI BABANGIDA UNIVERSITY LAPAI.</h2>
        <h4>
          {currentSession} Academic Session - {currentSemester}
        </h4>
        <p>
          (<i>Office Of The Registrar</i>)
        </p>
      </div>
    </div>
  );
};

export default IbbuHeader;
