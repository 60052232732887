import { Button } from "react-bootstrap";

const DesktopTable = ({ paymentDetails }: any) => {
  return (
    <>
      {paymentDetails.length > 0 ? (
        <div className="desktop-table">
          <table className="course-table">
            <thead>
              <tr>
                <th>
                  {" "}
                  <span>SESSION</span>
                </th>
                <th>
                  {" "}
                  <span>LEVEL</span>
                </th>
                <th>
                  {" "}
                  <span>PAYMENT TYPE</span>
                </th>
                <th>
                  <span>DOWNLOAD</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {paymentDetails.map((data: any, index: any) => {
                const encodedSession = encodeURIComponent(data.session);
                const encodedType = encodeURIComponent(data.type);
                return (
                  <tr key={index}>
                    <td>
                      <span>{data?.session}</span>
                    </td>
                    <td>
                      {" "}
                      <span>{data?.level}</span>
                    </td>
                    <td>
                      {" "}
                      <span>{data?.paymentType}</span>
                    </td>
                    <td className="button-span">
                      <span>
                        {" "}
                        <Button
                          className="paid-btn"
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`https://ibbuportals.ibbu.edu.ng/Verify?code=${data.studentId}&session=${encodedSession}&type=${encodedType}`}
                        >
                          Download
                        </Button>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No receipt is currently available.</p>
      )}
    </>
  );
};

export default DesktopTable;
