import React from "react";
import Payments from "../../../assets/paystack-others.png";
import { useSelector } from "react-redux";

const Invoice = ({ studentInfo }: any) => {
  const { amount, transactionId, downloadUrl } = useSelector(
    (state: any) => state.eDocumentation
  );
  const { fullName, email, phoneNumber } = studentInfo;
  return (
    <div className="invoice-container">
      <h3>Payment Invoice</h3>
      <div className="payment-invoice">
        <div className="trans-row">
          <strong className="red">Transaction ID:</strong>
          <strong className="red">{transactionId}</strong>
        </div>
        <div className="trans-row">
          <strong>Payment item:</strong>
          <strong>E-Documentation Fee</strong>
        </div>
        <div className="trans-row">
          <strong>Payer Full Name:</strong>
          <strong>{fullName}</strong>
        </div>
        <div className="trans-row">
          <strong className="red">Amount Payable:</strong>
          <strong className="red">{amount}</strong>
        </div>
        <div className="trans-row">
          <strong>Phone Number:</strong>
          <strong>{phoneNumber}</strong>
        </div>
        <div className="trans-row">
          <strong>Email Address:</strong>
          <strong>{email}</strong>
        </div>
        <div className="trans-row">
          <strong>Generated Date:</strong>
          <strong>Jan 1, 2024</strong>
        </div>
      </div>
      <div className="payments">
        <img src={Payments} />
      </div>
      <div className="generate-edoc-invoice">
        <a target="_blank" className="edoc-link" href={downloadUrl}>
          Download Invoice
        </a>
      </div>
    </div>
  );
};

export default Invoice;
