export const appConstantsUGradOnboarding ={

    LOGIN_USER_SUCCESS : 'LOGIN_USER_SUCCESS',
    LOGIN_USER_PENDING : 'LOGIN_USER_PENDING',
    LOGIN_USER_FAILURE : 'LOGIN_USER_FAILURE',
    LOGIN_USER_RESET : 'LOGIN_USER_RESET',
    
    LOGOUT: 'USERS_LOGOUT',

    FORGOT_PASSWORD_SUCCESS : 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_PENDING : 'FORGOT_PASSWORD_PENDING',
    FORGOT_PASSWORD_FAILURE : 'FORGOT_PASSWORD_FAILURE',
    FORGOT_PASSWORD_RESET :   'FORGOT_PASSWORD_RESET',

    VERFIY_PASSWORD_TOKEN_SUCCESS : 'VERFIY_PASSWORD_TOKEN_SUCCESS',
    VERFIY_PASSWORD_TOKEN_PENDING : 'VERFIY_PASSWORD_TOKEN_PENDING',
    VERFIY_PASSWORD_TOKEN_FAILURE : 'VERFIY_PASSWORD_TOKEN_FAILURE',
    VERFIY_PASSWORD_TOKEN_RESET :   'VERFIY_PASSWORD_TOKEN_RESET',

    UPDATE_NEW_PASSWORD_SUCCESS : 'UPDATE_NEW_PASSWORD_SUCCESS',
    UPDATE_NEW_PASSWORD_PENDING : 'UPDATE_NEW_PASSWORD_PENDING',
    UPDATE_NEW_PASSWORD_FAILURE : 'UPDATE_NEW_PASSWORD_FAILURE',
    UPDATE_NEW_PASSWORD_RESET :   'UPDATE_NEW_PASSWORD_RESET',

    CHANGE_PASSWORD_SUCCESS : 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_PENDING : 'CHANGE_PASSWORD_PENDING',
    CHANGE_PASSWORD_FAILURE : 'CHANGE_PASSWORD_FAILURE',
    CHANGE_PASSWORD_RESET :   'CHANGE_PASSWORD__RESET',
}