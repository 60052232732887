// import SuccessIcon from "../../../assets/images/icons/check-circle.png"
// import ErrorIcon from "../../../assets/images/icons/x-circle.png"
import { Link } from "react-router-dom";
import "./index.scss";
export const AlertMsg = ({ type, message }: any) => {
  return (
    <div className={`alert-msg ${type}`}>
      {/* {type === "success" &&
                <img className="alert-icon" src={SuccessIcon} alt="" />
            }
            {type === "error" &&
                <img className="alert-icon" src={ErrorIcon} alt="" />
            } */}

      {type === "error" && (
        <div className="alert-txt">{message || "An Error Occured"} </div>
      )}
      {type === "success" && (
        <div className="alert-txt">{message || "Successful"} </div>
      )}

      {type === "error" && message.indexOf("school fee") >= 1 && (
        <div className="action_link">
          <Link to="/undergraduate/payments/school-fee">
            {" "}
            Click here to pay your school fee{" "}
          </Link>
        </div>
      )}

      {type === "error" && message.indexOf("all fees") >= 1 && (
        <div className="action_link">
          <Link to="/undergraduate/dashboard">
            {" "}
            Click here to see pending fees{" "}
          </Link>
        </div>
      )}

      {type === "error" && message.indexOf("update your Bio-data") >= 1 && (
        <div className="action_link">
          <Link to="/undergraduate/personal-data/update">
            {" "}
            Click here to update your Bio data{" "}
          </Link>
        </div>
      )}
    </div>
  );
};
